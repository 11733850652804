import { Component, Input, OnInit } from '@angular/core';
import {
  Notification,
  NotificationResponse,
} from '@app/model/interfaces/notification';
import { UserDetailsResponse } from '@app/model/interfaces/settings';
import { HeaderService } from '@app/shared/services/header.service';
import { UserInfoService } from '@app/shared/services/user-info.service';
import { SettingsService } from '../settings/settings.service';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { DataSharingService } from '@app/shared/services/data-sharing.service';
import { Router } from '@angular/router';
import { LanguageService } from '@app/shared/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { getEncodingURL } from '@app/shared/utils/functions';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { SharedService } from '@app/shared/shared.service';

@Component({
  selector: 'clover-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  moaList!: Notification[];
  nonMoaList!: Notification[];
  notificationResultList: Notification[] = [];
  notificationActionList: Notification[] = [];
  notificationDownLaodList: Notification[] = [];
  slicedMoaList: Notification[] = [];
  isopenNotifiaction = false;
  showloader = false;
  hasImage: boolean | undefined;
  hasMoaPriviledge = Boolean(
    this.sharedService.getProfileDetails().moaPrivilege
  );
  viewMoreClicked = false;
  eventsSubscription?: Subscription;
  subscriptionAudienceActionData!: Subscription;
  subscriptionReportData!: Subscription;
  notificationStaticContent = {
    notificationClear: 'notification.msg.success.clear',
    serverError: 'notification.msg.server.error',
  };
  staticContent = this.language.getLocaleString(this.notificationStaticContent);
  @Input() events?: Observable<boolean>;
  @Input() isPopup = false;
  maxNotificationId = 0;
  unreadNotification = false;
  constructor(
    private headerService: HeaderService,
    private settingService: SettingsService,
    private localStorageService: LocalStorageService,
    private userInfoService: UserInfoService,
    private dataSharingService: DataSharingService,
    private router: Router,
    private sharedService: SharedService,
    private translate: TranslateService,
    private language: LanguageService
  ) {}

  ngOnInit(): void {
    this.viewMoreClicked = false;
    this.dataSharingService.bellNotificationEvent.subscribe({
      next: () => {
        this.getLatestNotifications();
      },
    });
    this.dataSharingService
      .onNotificationUpdateEvent()
      .subscribe((data: Notification) => {
        const notificationIndex = this.notificationResultList.findIndex(
          (item: Notification) => item.id === data.id
        );
        this.notificationResultList[notificationIndex] = data;
        this.updateNotificationData(this.notificationResultList);
      });

    this.language.languageChanged.subscribe({
      next: (res: string) => {
        this.translate.setDefaultLang(res);
      },
      error: (error) => {},
      complete: () => {},
    });
    if (this.events) {
      this.eventsSubscription = this.events.subscribe((value) => {
        this.isopenNotifiaction = false;
      });
    }
  }
  getLatestNotifications(): void {
    const userId = this.userInfoService.getUserId();
    this.headerService
      .getLatestUserNotificationCount(userId, this.maxNotificationId)
      .subscribe((data: NotificationResponse) => {
        if (data.errorCode === '0') {
          if (this.maxNotificationId !== data.maxNotificationId) {
            this.maxNotificationId = data.maxNotificationId;
            this.loadNotifications();
          } else {
            this.notificationResultList = [];
            this.updateNotificationData(this.notificationResultList);
          }
        }
      });
  }
  loadNotifications(): void {
    this.slicedMoaList = [];
    this.moaList = [];
    this.nonMoaList = [];
    this.notificationActionList = [];
    this.notificationResultList = [];
    this.notificationDownLaodList = [];
    this.showloader = true;
    const userId = this.userInfoService.getUserId();
    this.headerService
      .getUserNotification(userId)
      .subscribe((data: NotificationResponse) => {
        if (data.notifications) {
          this.showloader = false;
          this.notificationResultList = data.notifications;
          this.updateNotificationData(this.notificationResultList);
        }
      });
  }
  updateNotificationData = (notificationArray: Notification[]) => {
    this.moaList = notificationArray.filter(
      (val) => val.notificationGroupName === 'Prospect'
    );
    this.nonMoaList = notificationArray.filter(
      (val) => val.notificationGroupName !== 'Prospect'
    );

    this.notificationActionList = this.nonMoaList.filter(
      (val) => val.notificationGroupName !== 'DownloadGroup'
    );
    this.notificationDownLaodList = this.nonMoaList.filter(
      (val) => val.notificationGroupName === 'DownloadGroup'
    );
    let moaUnreadNotification = false;
    if (this.hasMoaPriviledge) {
      moaUnreadNotification = Boolean(
        this.moaList.filter((item: Notification) => !item.isVisited).length
      );
    }
    const actionUnreadNotification = this.notificationActionList.filter(
      (item: Notification) => !item.isVisited
    ).length;
    const downLoadUnreadNotification = this.notificationDownLaodList.filter(
      (item: Notification) => !item.isVisited
    ).length;
    this.unreadNotification = Boolean(
      moaUnreadNotification ||
        actionUnreadNotification ||
        downLoadUnreadNotification
    );

    this.setDataAndSort();
    this.notificationActionList = this.notificationActionList.slice(0, 3);
    this.slicedMoaList = this.moaList.slice(0, 3);
    this.notificationDownLaodList = this.notificationDownLaodList.slice(0, 3);
    this.loadUserDetails();
  };
  getEncodingURL = (imagePath: string | undefined) => getEncodingURL(imagePath);
  setDataAndSort(): void {
    this.moaList = this.moaList.sort(
      (a: Notification, b: Notification) =>
        Date.parse(b.createdDate) - Date.parse(a.createdDate)
    );
    this.notificationActionList = this.notificationActionList.sort(
      (a: Notification, b: Notification) =>
        Date.parse(b.createdDate) - Date.parse(a.createdDate)
    );
    this.notificationDownLaodList = this.notificationDownLaodList.sort(
      (a: Notification, b: Notification) =>
        Date.parse(b.createdDate) - Date.parse(a.createdDate)
    );
  }
  setNotificationBody(
    item: Notification,
    ressultMap: Map<string, Notification[]>
  ): void {
    if (item.notificationBody) {
      try {
        item.notificationBodyParsed = JSON.parse(item.notificationBody);
      } catch {
        item.notificationBodyParsed = undefined;
      }
      if (
        item.notificationBodyParsed &&
        item.notificationBodyParsed.sourceUserId
      ) {
        const userDetails = this.dataSharingService.getUserInfoMap(
          item.notificationBodyParsed.sourceUserId.toLowerCase()
        );
        if (userDetails) {
          item.userName = userDetails.firstName + ' ' + userDetails.lastName;
          item.notificationBodyParsed.textTemplate =
            item.notificationBodyParsed.textTemplate.replace(
              '{username}',
              item.userName
            );
          item.profileImage = userDetails.imageUrl;
          item.hasImage = item.profileImage ? true : false;
        } else {
          const key = item.notificationBodyParsed.sourceUserId.toLowerCase();
          if (ressultMap.has(key)) {
            ressultMap.get(key)?.push(item);
          } else {
            const arr = [];
            arr.push(item);
            ressultMap.set(key, arr);
          }
        }
      }
    }
  }
  loadUserDetails(): void {
    const ressultMap: Map<string, Notification[]> = new Map();
    this.notificationActionList.forEach((item: Notification) => {
      item.formattedDate = moment.utc(item.createdDate).fromNow();
      this.setNotificationBody(item, ressultMap);
    });
    const ressultMapDownLoad: Map<string, Notification[]> = new Map();
    this.notificationDownLaodList.forEach((item: Notification) => {
      item.formattedDate = moment.utc(item.createdDate).fromNow();
      this.setNotificationBody(item, ressultMap);
    });
    for (const keyVal of ressultMap.keys()) {
      this.settingService
        .getUserDetail(keyVal)
        .subscribe((user: UserDetailsResponse) => {
          if (user.userDetails) {
            ressultMap
              .get(user.userDetails.userId.toLowerCase())
              ?.forEach((itemVal) => {
                this.dataSharingService.setUserInfoMap(
                  user.userDetails.userId.toLowerCase(),
                  user.userDetails
                );
                itemVal.userName =
                  user.userDetails.firstName + ' ' + user.userDetails.lastName;
                itemVal.profileImage = user.userDetails.imageUrl;
                if (itemVal.notificationBodyParsed) {
                  itemVal.notificationBodyParsed.textTemplate =
                    itemVal.notificationBodyParsed.textTemplate.replace(
                      '{username}',
                      itemVal.userName
                    );
                }
                itemVal.hasImage = itemVal.profileImage ? true : false;
              });
          } else {
            ressultMap.get(keyVal)?.forEach((itemVal) => {
              if (itemVal.notificationBodyParsed) {
                itemVal.notificationBodyParsed.textTemplate =
                  itemVal.notificationBodyParsed.textTemplate.replace(
                    '{username}',
                    keyVal
                  );
              }
            });
          }
        });
    }
  }
  onClickViewAll(): void {
    this.router.navigate([`/notifications`]);
  }

  openNotification(): void {
    this.isopenNotifiaction = !this.isopenNotifiaction;
  }

  changeLanguage(language: string): void {
    this.userInfoService.setUserLanguage(language).subscribe((res) => {
      if (res.errorCode === '0') {
        this.language.setLanguage(language);
        this.localStorageService.setItem('getSelectedLanguage', language);
        window.location.reload();
      }
    });
  }
}
