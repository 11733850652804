import { Injectable } from '@angular/core';
import { WsEventResponse } from '@app/model/interfaces/common';
import { UserInfo } from '@app/model/interfaces/menu';
import { Notification } from '@app/model/interfaces/notification';
import { UserDetails } from '@app/model/interfaces/settings';
import { BehaviorSubject, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class DataSharingService {
  userInfo!: UserInfo;
  bankIdChanged = new BehaviorSubject(-1);
  bankListChanged = new BehaviorSubject(this.userInfo);
  profileImageChanged = new BehaviorSubject('');
  menuCountChanged = new BehaviorSubject({ name: '', count: 0 });
  bank$ = this.bankIdChanged.asObservable();
  bellNotificationEvent = new BehaviorSubject(null);
  callCompletedRTNotificationEvent = new BehaviorSubject(null);
  sequenceRTNotificationEvent = new Subject();
  notificationUpdateEvent = new Subject<Notification>();
  leadDataUpdated = new Subject();
  opportunityDataUpdated = new Subject();

  private userInfoMap: Map<string, any> = new Map();
  setUserInfoMap(key: string, value: UserDetails): void {
    this.userInfoMap.set(key, value);
  }

  updateLeadDataUpdated = (webSocketData: WsEventResponse) => {
    this.leadDataUpdated.next(webSocketData);
  };

  updateOpportunityDataUpdated = (webSocketData: WsEventResponse) => {
    this.opportunityDataUpdated.next(webSocketData);
  };
  updateNotificationData = (data: Notification) => {
    this.notificationUpdateEvent.next(data);
  };
  updateSequenceTaskNotification = (webSocketData: WsEventResponse) => {
    this.sequenceRTNotificationEvent.next(webSocketData);
  };
  onUpdateSequenceTask = () => this.sequenceRTNotificationEvent.asObservable();
  onUpdateLeadDataUpdated = () => this.leadDataUpdated.asObservable();
  onNotificationUpdateEvent = () => this.notificationUpdateEvent.asObservable();
  onUpdateOpportunityDataUpdated = () =>
    this.opportunityDataUpdated.asObservable();
  getUserInfoMap(key: string): UserDetails {
    return this.userInfoMap.get(key);
  }
  constructor() {}
}
