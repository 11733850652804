import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import {
  Field,
  FieldsResponse,
  GetSalesStageDataResponse,
  ProcessingVolumrResponse,
  SubmitReferralResponse,
} from '@app/model/interfaces/referral';
import {
  Expression,
  ReferralActivityReq,
  ReferralActivityResponse,
} from '@app/model/interfaces/referral-activity';
import { AutoSuggestResponse } from '@app/model/interfaces/sales';
import { pageSizeOptions } from '@app/appConstants';

@Injectable({
  providedIn: 'root',
})
export class ReferralService {
  private payload: ReferralActivityReq = {
    page: 0,
    fromDate: '',
    toDate: '',
    recordsPerPage: pageSizeOptions[0],
    sortBy: 'LEAD_CREATED_DATE',
    sortOrder: 'DESC',
    filters: {},
  };
  filterValueChanged: EventEmitter<string> = new EventEmitter<string>();
  constructor(private http: HttpClient) {}
  getReferralPayload(): ReferralActivityReq {
    return this.payload;
  }
  setReferralDate(fromDate: string, toDate: string): void {
    this.payload.fromDate = fromDate;
    this.payload.toDate = toDate;
  }

  setReferralFilter(filters: Expression = {}): void {
    this.payload.filters = filters;
    this.filterValueChanged.emit();
  }

  setReferralPayloadPage(page: number): void {
    this.payload.page = page;
  }

  setReferralPayloadRecordsPerPage(recordsPerPage: number): void {
    this.payload.recordsPerPage = recordsPerPage;
  }
  setReferralPayloadSortBy(sortBy: string): void {
    this.payload.sortBy = sortBy;
  }
  setReferralPayloadSortOrder(sortOrder: string): void {
    this.payload.sortOrder = sortOrder;
  }

  getFieldsDataForForm(bankId: number): Observable<FieldsResponse> {
    return this.http
      .get(
        `${environment.referralServices}/v1/bank/${bankId}/bankReferral/fields`
      )
      .pipe(
        catchError(this.handleError),
        tap((response: FieldsResponse) => {})
      );
  }

  getFieldsDataForUpload(bankId: number): Observable<FieldsResponse> {
    return this.http
      .get(
        `${environment.referralServices}/v1/bank/${bankId}/bankReferral/uploadfields`
      )
      .pipe(
        catchError(this.handleError),
        tap((response: FieldsResponse) => {})
      );
  }
  getAutoCompleteData(searchData: string, fieldID: number): Observable<Field> {
    return this.http
      .get(
        `${environment.referralServices}/v1/bankReferral/fields/${fieldID}/options/${searchData}/`
      )
      .pipe(
        catchError(this.handleError),
        tap((response: Field) => {})
      );
  }

  getReferralChartData(
    payload: Expression,
    bankId: number
  ): Observable<ReferralActivityResponse> {
    return this.http
      .post(
        `${environment.referralServices}/v1/bank/${bankId}/referralActivity`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap((response: ReferralActivityResponse) => {})
      );
  }

  submitReferralOpportunity(
    payload = {},
    bankId: number
  ): Observable<SubmitReferralResponse> {
    return this.http
      .post(
        `${environment.referralServices}/v1/bank/${bankId}/bankReferral`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap((response: SubmitReferralResponse) => {})
      );
  }

  uploadReferralOpportunity(
    payload = {},
    bankId: number
  ): Observable<SubmitReferralResponse> {
    return this.http
      .post(
        `${environment.referralServices}/v1/bank/${bankId}/UploadBankReferral`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap((response: SubmitReferralResponse) => {})
      );
  }

  getMerchantReferralActivity(
    payload: Expression,
    bankId: number,
    type: string
  ): Observable<FieldsResponse> {
    return this.http
      .post(
        environment.referralServices + `/v1/bank/${bankId}/referral/${type}/`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap((response: FieldsResponse) => {})
      );
  }

  getExportData(
    payload: ReferralActivityReq,
    bankId: number
  ): Observable<FieldsResponse> {
    return this.http
      .post(
        environment.referralServices + `/v2/bank/${bankId}/referrals/download`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap((response: FieldsResponse) => {})
      );
  }

  getSalesStageData(
    bankId: number,
    fromDate: string,
    toDate: string
  ): Observable<GetSalesStageDataResponse> {
    return this.http
      .get(
        `${environment.referralServices}/v1/bank/${bankId}/referral/funnel?fromDate=${fromDate}&toDate=${toDate}`
      )
      .pipe(
        catchError(this.handleError),
        tap((response: GetSalesStageDataResponse) => {})
      );
  }

  getNewActivationsData(bankId: number): Observable<ProcessingVolumrResponse> {
    return this.http
      .get(
        `${environment.referralServices}/v1/bank/${bankId}/referrals/activations/ytd`
      )
      .pipe(
        catchError(this.handleError),
        tap((response: ProcessingVolumrResponse) => {})
      );
  }

  getProcessingVolumeData(
    bankId: number
  ): Observable<ProcessingVolumrResponse> {
    return this.http
      .get(
        `${environment.referralServices}/v1/bank/${bankId}/referrals/processingVolume/ytd`
      )
      .pipe(
        catchError(this.handleError),
        tap((response: ProcessingVolumrResponse) => {})
      );
  }

  getAutoSuggestForBranch(
    bankId: number,
    code: string,
    keyword: string | number
  ): Observable<AutoSuggestResponse> {
    return this.http
      .get<AutoSuggestResponse>(
        `${environment.referralServices}/v1/bank/${bankId}/bankReferral/fields/${code}/options/${keyword}/`
      )
      .pipe(
        tap((response: AutoSuggestResponse) => {}),
        catchError(this.handleError)
      );
  }
  getReferralUserDetails = (referralId: number): Observable<any> => {
    const url = `${environment.salesRegimeServices}/v1/lead/referral/${referralId}`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: any) => {})
    );
  };
  handleError(error: HttpErrorResponse): Observable<any> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
