<div class="dialog-container">
  <div class="m-3">
    <div class="mb-3 d-flex align-items-center justify-content-between">
      <h1 class="section-title">
        {{ getRiskAlertsTitleText(notificationData) | translate }}
      </h1>
      <img
        class="pointer"
        src="assets/images/icons/icon_dialog_close.svg"
        (click)="onCancel()"
      />
    </div>
    <p class="pageTitle-desc">
      {{ notificationData.notificationBodyParsed?.textTemplate }}
    </p>
  </div>
  <div class="simple-table-container m-3">
    <div class="col-md-12 mt-3 fixedheight">
      <table mat-table [dataSource]="dataSource">
        <ng-container
          *ngFor="
            let disCol of displayColumnsDefined;
            let colIndex = index;
            let last = last
          "
          matColumnDef="{{ disCol.dataFieldName }}"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            [ngStyle]="{ width: '200px' }"
            class="statusUpdate"
          >
            <span>
              {{ disCol.multiLanguageKey | translate }}
            </span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="disCol.dataFieldName == 'mid'">
              {{ element.mid || '--' }}
            </span>
            <span *ngIf="disCol.dataFieldName == 'accountName'">
              <a
                class="link"
                (click)="navigateToRiskAlerts(notificationData, element.accountId)"
              >
                {{ element.accountName || '--' }}
              </a>
            </span>
            <span *ngIf="disCol.dataFieldName == 'riskType'">
              {{ element.riskType || '--' }}
            </span>
            <span *ngIf="disCol.dataFieldName == 'status'">
              {{ element.status || '--' }}
            </span>
            <span *ngIf="disCol.dataFieldName == 'communicationType'">
              {{ element.communicationType || '--' }}
            </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <div class="t-50" *ngIf="isRiskAlertsBatchReportLoading">
        <mat-spinner class="align-center mat-spinner" diameter="50"></mat-spinner>
      </div>
      <clover-no-data
        *ngIf="dataSource.data.length === 0 && !isRiskAlertsBatchReportLoading"
        message="{{ 'risk.alerts.norecords' | translate }}"
      ></clover-no-data>
    </div>
  </div>      
</div>
