import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeaturesComponent } from '@app/features/features.component';
import { AuthGuard } from '@shared/guard/auth.guard';
import { SearchResultComponent } from './features/search-results/search-result/search-result.component';
import { AllNotificationsDialogComponent } from './features/all-notifications-dialog/all-notifications-dialog.component';

const isSmartDevice = /iphone|ipad|android|ie|blackberry|fennec/.test(
  navigator.userAgent.toLowerCase()
);

const routes: Routes = [
  {
    path: '',
    component: FeaturesComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'overview' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('@app/features/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'overview',
        loadChildren: () =>
          import('@app/features/overview/overview.module').then(
            (m) => m.OverviewModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'moa',
        loadChildren: () =>
          import('@app/features/prospects/prospects.module').then(
            (m) => m.ProspectsModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'opportunities',
        loadChildren: () =>
          import('@app/features/opportunities/opportunities.module').then(
            (m) => m.OpportunitiesModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'marketing',
        loadChildren: () =>
          import('@app/features/marketing/marketing.module').then(
            (m) => m.MarketingModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'agenda',
        loadChildren: () =>
          import('@app/features/agenda/agenda.module').then(
            (m) => m.AgendaModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'activityfeed',
        loadChildren: () =>
          import('@app/features/activity-feed/activity-feed.module').then(
            (m) => m.ActivityFeedModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'audience',
        loadChildren: () =>
          import('@app/features/audience/audience.module').then(
            (m) => m.AudienceModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'referral',
        loadChildren: () =>
          import('@app/features/referral/referral.module').then(
            (m) => m.ReferralModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'myteam',
        loadChildren: () =>
          import('@app/features/my-team/my-team.module').then(
            (m) => m.MyTeamModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'myaccounts',
        loadChildren: () =>
          import('@app/features/my-accounts/my-accounts.module').then(
            (m) => m.MyAccountsModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'leads',
        loadChildren: () =>
          import('@app/features/leads/leads.module').then((m) => m.LeadsModule),
        canLoad: [AuthGuard],
      },
      {
        path: 'branch',
        loadChildren: () =>
          import('@app/features/branches/branches.module').then((m) => m.BranchesModule),
        canLoad: [AuthGuard],
      },
      {
        path: 'sales',
        loadChildren: () =>
          import('@app/features/sales-routing/sales.module').then(
            (m) => m.SalesModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('@app/features/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'campaign',
        loadChildren: () =>
          import('@app/features/campaign/campaign.module').then(
            (m) => m.CampaignModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'sequence',
        loadChildren: () =>
          import('@app/features/sequence-builder/sequence-builder.module').then(
            (m) => m.SequenceBuilderModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'academy',
        loadChildren: () =>
          import('@app/features/academy/academy.module').then(
            (m) => m.AcademyModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'messages',
        loadChildren: () =>
          import('@app/features/message/message.module').then(
            (m) => m.MessageModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'searchResults',
        component: SearchResultComponent,
      },
      {
        path: 'notifications',
        component: AllNotificationsDialogComponent,
      },
    ],
  },
];

const mobileRoutes: Routes = [
  {
    path: '',
    component: FeaturesComponent,
    children: [
      {
        path: 'referral',
        loadChildren: () =>
          import('@app/features/referral/referral.module').then(
            (m) => m.ReferralModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'leads',
        loadChildren: () =>
          import('@app/features/mobile-view/mv-leads/mv-leads.module').then(
            (m) => m.MvLeadsModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('@app/features/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
        canLoad: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(isSmartDevice ? mobileRoutes : routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
