import { Component, Input } from '@angular/core';

@Component({
  selector: 'clover-no-data',
  template: ` <div
    class="d-flex flex-column justify-content-center align-items-center {{
      space
    }}"
  >
    <p><img src="assets/images/icons/icon_info_alert_1.svg" /></p>
    <p class="primary-color">{{ message }}</p>
  </div>`,
})
export class NoDataComponent {
  @Input() space: string = 'p-5';
  @Input() message = '';
  constructor() {}
}
