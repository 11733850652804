import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class PaginatorI18n {
  itemsPerPage!: string;

  constructor(private readonly translate: TranslateService) {}

  getPaginatorIntl(): MatPaginatorIntl {
    this.translate
      .get('paginator.item.per.page.label')
      .subscribe((text: string) => (this.itemsPerPage = text));
    const paginatorIntl = new MatPaginatorIntl();
    paginatorIntl.itemsPerPageLabel = this.translate.instant(this.itemsPerPage);
    return paginatorIntl;
  }
}
