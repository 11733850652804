import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { defaultMenus } from '@app/appConstants';
import { AgendaService } from '@app/features/agenda/agenda.service';
import { agendaTabset } from '@app/features/agenda/agendaConstants';
import { MessageService } from '@app/features/message/message.service';
import {
  AgendaFilterObject,
  NewAgendaTaskCountResponse,
} from '@app/model/interfaces/agenda';
import { Menu, MenuItem } from '@app/model/interfaces/menu';
import { BankInfoService } from '@app/shared/services/bank-info.service';
import { DataSharingService } from '@app/shared/services/data-sharing.service';
import { LanguageService } from '@app/shared/services/language.service';
import { UserInfoService } from '@app/shared/services/user-info.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'clover-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent implements OnInit {
  menuList: Menu[] = defaultMenus;
  hasAgendaAccess = false;
  expandMenu = false;
  isMoaEnabled = this.bankInfoService.checkMOAEnabledBank();
  @Output() expandMenuEvent = new EventEmitter();
  hasMessagesAccess = false;
  constructor(
    private userInfoService: UserInfoService,
    private translate: TranslateService,
    private agendaService: AgendaService,
    private messageService: MessageService,
    private dataSharing: DataSharingService,
    private language: LanguageService,
    private bankInfoService: BankInfoService
  ) {}

  ngOnInit(): void {
    const menus: MenuItem[] = this.userInfoService.getMenu();
    this.menuList.forEach((value) => {
      const menuItem = menus.find(
        (obj: MenuItem) => obj.name === value.name && obj.access === true
      );
      if (menuItem?.access) {
        value.access = true;
        if (menuItem.name === 'agenda') {
          this.hasAgendaAccess = true;
        }
        if (menuItem.name === 'messages') {
          this.hasMessagesAccess = true;
        }
      } else {
        value.access = false;
      }
    });
    this.language.languageChanged.subscribe({
      next: (res: string) => {
        this.translate.setDefaultLang(res);
      },
      error: (error) => {},
      complete: () => {},
    });

    this.dataSharing.menuCountChanged.subscribe({
      next: (res: any) => {
        this.updateMenuCount(res.name, res.count);
      },
    });
    if (this.hasAgendaAccess) {
      this.agendaService.updateAgendaTaskCount.subscribe({
        next: (res: string) => {
          this.getAgendaTaksInfo();
        },
      });
    }
    if (this.hasMessagesAccess) {
      this.messageService.updateUnReadMsgCountEvent.subscribe({
        next: (res: string) => {
          this.getMessagesCount();
        },
      });
    }
  }

  toggleExpandMenu = () => {
    this.expandMenu = !this.expandMenu;
    this.expandMenuEvent.emit({
      expandMenu: this.expandMenu,
    });
  };

  menuChanged(activateUrl: string): void {
    this.menuList.forEach((element) => {
      if (element.activateUrl === activateUrl) {
        if (activateUrl === 'MR') {
          element.url = '/referral/my-referral';
          element.label = 'My Referrals';
          element.activateUrl = 'RA';
        } else {
          element.url = '/referral/referral-activity';
          element.label = 'Referral Activity';
          element.activateUrl = 'MR';
        }
      }
    });
  }

  getAgendaTaksInfo = () => {
    let filterKey = '';
    agendaTabset.forEach((item: AgendaFilterObject, index: number) => {
      filterKey += `${item.filter.toUpperCase()}${
        index < agendaTabset.length - 1 ? ':' : ''
      }`;
    });
    this.agendaService.getNewAgendaTasksCount(filterKey).subscribe(
      (res: NewAgendaTaskCountResponse) => {
        if (res.statusCode === 200) {
          let totalTaskCount = 0;
          agendaTabset.forEach((item: AgendaFilterObject) => {
            const filterText = item.filter.toUpperCase();
            if (res.data[filterText] > 0) {
              totalTaskCount += res.data[filterText];
            }
          });
          this.updateMenuCount('agenda', totalTaskCount);
        }
      },
      (err) => {}
    );
  };

  getMessagesCount = () => {
    const userId = this.userInfoService.getUserId();
    this.messageService.getListOfOpenConversations(userId).subscribe(
      (response: any) => {
        if (response.statusCode === 200) {
          let totalUnReadMsgCount = 0;
          response.data.forEach((user: any) => {
            totalUnReadMsgCount += Number(user.unreadMsgCnt);
          });
          this.updateMenuCount('messages', totalUnReadMsgCount);
        }
      },
      (err) => {}
    );
  };

  updateMenuCount = (menuName: string, count: number) => {
    this.menuList.forEach((menu: any) => {
      if (menu.name === menuName) {
        menu.count = count;
      }
    });
  };
}
