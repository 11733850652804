import { Component } from '@angular/core';
import { AdhocMessageService } from '@app/shared/services/adhoc-message.service';
import { HeaderService } from '@app/shared/services/header.service';
import { UserInfoService } from '@app/shared/services/user-info.service';

@Component({
  selector: 'clover-adhoc-message-container',
  templateUrl: './adhoc-message-container.component.html',
  styleUrls: ['./adhoc-message-container.component.scss'],
})
export class AdhocMessageContainerComponent {
  conversationList: any = [];
  maxChatWindow = this.userInfo.getUserDevice() ? 3 : 1;
  
  constructor(
    private adhocMessageService: AdhocMessageService,
    private userInfo: UserInfoService,
    private headerService: HeaderService
  ) {}
  ngOnInit(): void {
    this.adhocMessageService
      .onUpdateConversationListUpdated()
      .subscribe((res: any) => {
        if (res.action === 'new') {
          let isWindowActive = false;
          this.conversationList.forEach((conversion: any) => {
            if (conversion.user.entityId === res.data.user.entityId) {
              isWindowActive = true;
            }
          });
          if (isWindowActive) return;
          if (this.conversationList.length >= this.maxChatWindow) {
            this.conversationList.shift();
          }
          this.conversationList.push(res.data);
        }
        
        if (res.action === 'update') {
          setTimeout(() => {
            let windowPos = -1;
            this.conversationList.forEach((conversion: any, index: number) => {
              if (conversion.user.entityId == res.data.leadId) {
                windowPos = index;
              }
            });
            if (windowPos >= 0) {
              this.conversationList.splice(windowPos, 1, res.data);
            }
          }, 10000);
        }

        if (res.action === 'close') {
          let windowPos = -1;
          this.conversationList.forEach((conversion: any, index: number) => {
            if (conversion.user.entityId === res.data.user.entityId) {
              windowPos = index;
            }
          });
          if (windowPos != -1) {
            this.conversationList.splice(windowPos, 1);
          }
        }
      });
  }
}
