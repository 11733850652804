import { Component, OnInit } from '@angular/core';
import { UserInfoService } from '@app/shared/services/user-info.service';

@Component({
  selector: 'clover-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent implements OnInit {
  showOnDesktop: boolean = this.userInfo.getUserDevice();
  expandMenu = false;
  isSmartDevice = false;
  constructor(private userInfo: UserInfoService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.isSmartDevice = !this.userInfo.getUserDevice();
    }, 100);
  }
  ngAfterViewChecked(): void {}
  updateExpandMenuStatus = (event: { expandMenu: boolean }) => {
    this.expandMenu = event.expandMenu;
  };
}
