<mat-toolbar class="p-2 px-3 justify-content-between header">
  <div class="logo d-flex">
    <div
      class="menuToggle"
      [ngClass]="{ toggle: menuActive }"
      (click)="setMenuToggle()"
      *ngIf="!showOnDesktop"
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
    <a routerLink="/" class="logo" *ngIf="showOnDesktop">
      <img src="assets/images/icons/icon_cloverLogo.svg" />
    </a>
    <a class="logo smartDevice" *ngIf="!showOnDesktop">
      <img src="assets/images/icons/icon_cloverLogo.svg" class="smartDevice" />
    </a>
    <div
      class="search-list-container"
      *ngIf="
        bankSearchSelectionPopupActive &&
        (displayBankSelector ||
          (showMoaLabel &&
            ['admin', 'contributor'].includes(moaRole.toLowerCase())))
      "
    >
      <div
        class="back-overlay"
        [ngClass]="{ smartDevice: !showOnDesktop }"
        (click)="setBankSearchSelectionPopupActive(false)"
      ></div>
      <div class="search-list" [ngClass]="{ smartDevice: !showOnDesktop }">
        <div class="search-input-container">
          <div>
            <input
              type="text"
              placeholder="Search"
              [(ngModel)]="bankSearchTxt"
              (keyup)="filterBankList()"
              autocomplete="disable"
            />
            <img
              class="mobile-search"
              src="assets/images/icons/icon_search.svg"
            />
          </div>
        </div>
        <div class="search-item-scrollWrapper">
          <div
            class="search-item"
            *ngFor="let bank of filteredBanksList"
            (click)="onBankChange(bank)"
          >
            {{ bank.name }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="selected-bank-info"
      [ngClass]="{ smartDevice: !showOnDesktop }"
      *ngIf="banksList.length == 1 && displayBankSelector"
    >
      {{ selectedBank?.name }}
    </div>
    <div
      class="selected-bank-info"
      [ngClass]="{ smartDevice: !showOnDesktop }"
      *ngIf="
        banksList.length > 1 &&
        (displayBankSelector ||
          (showMoaLabel &&
            ['admin', 'contributor'].includes(moaRole.toLowerCase())))
      "
      (click)="setBankSearchSelectionPopupActive(true)"
    >
      {{ selectedBank?.name }}
      <img src="assets/images/icons/icon_angle_down.svg" />
    </div>
    <div
      class="moaEnabledWrapper"
      [ngClass]="{
        'selected-bank-info pt-1 pe-none':
          showMoaLabel && moaRole.toLowerCase() !== 'admin'
      }"
      *ngIf="showOnDesktop && showMoaLabel && moaRole"
    >
      <span
        [ngClass]="{
          moaAdmin: moaRole.toLowerCase() === 'admin',
          moaContributor: moaRole.toLowerCase() === 'contributor'
        }"
        >{{ moaRole }}</span
      >
    </div>
  </div>

  <div class="navRight" *ngIf="showOnDesktop">
    <ul>
      <li>
        <div class="searchbox">
          <input
            [(ngModel)]="searchTerm"
            (keypress)="restrictSpecialChar($event)"
            (keyup)="removeSpecialChar()"
            (keyup.enter)="onClickSearch()"
            type="text"
            class="bg-light w-100 fs-14 border-0 small"
            placeholder="{{ 'userMenu.searchFor' | translate }}"
            autocomplete="disable"
          />
          <div *ngIf="searchTerm && searchTerm.length >= 3">
            <button
              class="btn btn-primary searchBtn"
              type="button"
              (click)="onClickSearch()"
            >
              <img src="assets/images/icons/icon_search.svg" />
            </button>
          </div>
        </div>
      </li>
      <li
        class="positionRelative language"
        *ngIf="languageList && languageList.length > 1"
      >
        <span [matMenuTriggerFor]="languageMenu">
          <img
            src="assets/images/icons/icon_globe.svg"
            title="{{ getLangauge(selectedLanguage).toUpperCase() }} Selected"
          />
        </span>
        <mat-menu #languageMenu="matMenu">
          <button
            mat-menu-item
            *ngFor="let language of languageList"
            (click)="changeLanguage(language)"
            [title]="getLangauge(language).toUpperCase()"
            [ngClass]="{ selectedLanguage: selectedLanguage === language }"
          >
            {{ getLangauge(language).toUpperCase() }}
          </button>
        </mat-menu>
      </li>
      <li class="positionRelative border-end notificationLink">
        <clover-notifications
          [events]="hideNotificationPanel.asObservable()"
          [isPopup]="true"
          cloverOutsideClick
          (appOutsideClick)="outsideClick($event)"
        ></clover-notifications>
      </li>

      <li>
        <div [matMenuTriggerFor]="menu">
          <img
            *ngIf="hasImage"
            class="img-profile rounded-circle"
            [src]="getEncodingURL(profileImageUrl)"
          />
          <span class="profileImage" *ngIf="!hasImage">
            <img src="assets/images/icons/icon_user.svg" />
          </span>
        </div>
        <mat-menu #menu="matMenu">
          <button *ngIf="hasSettingPageAccess" mat-menu-item>
            <a class="menu-link" routerLink="/settings"
              >{{ 'userMenu.settings' | translate }}
            </a>
          </button>
          <button mat-menu-item (click)="logout()">
            {{ 'userMenu.logout' | translate }}
          </button>
        </mat-menu>
      </li>
    </ul>
    <div class="positionRelative border-end notificationLink">
      <clover-notification-drawer></clover-notification-drawer>
    </div>
  </div>
</mat-toolbar>
<div
  class="siteMenu"
  [ngClass]="{ slideMenu: menuActive }"
  *ngIf="!showOnDesktop"
>
  <ul>
    <li class="userProfileDisplay" (click)="setMenuToggle()">
      <div>
        <img
          *ngIf="hasImage"
          class="img-profile rounded-circle"
          [src]="getEncodingURL(profileImageUrl)"
        />
        <span class="profileImage" *ngIf="!hasImage">
          <img src="assets/images/icons/icon_user.svg" />
        </span>
      </div>
      <div>
        <div class="mobileUsername">{{ userName }}</div>
        <div class="logout" (click)="logout()">
          <img src="assets/images/icons/icon_signout.svg" />
        </div>
      </div>
    </li>
    <ng-container *ngFor="let menu of mobileMenuList">
      <li routerLinkActive="active-link" #rla_overview1="routerLinkActive">
        <a
          routerLink="/{{ menu.url }}"
          (click)="setMenuToggle()"
          *ngIf="menu.access"
        >
          <img
            src="assets/images/nav_images/{{
              rla_overview1.isActive ? 'nav_on_' : 'nav_'
            }}{{ menu.image }}.svg"
          />{{ menu.label }}
        </a>
      </li>
    </ng-container>
  </ul>
</div>
<div
  class="slidingMenuShadow"
  (click)="setMenuToggle()"
  [ngClass]="{ showShadow: menuActive }"
  *ngIf="!showOnDesktop"
></div>
