import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapService {
  constructor(private http: HttpClient) {}

  getCityNState(zip: string): Observable<any> {
    const url = `${environment.referralServices}/v1/google/map/postalCode/${zip}`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: any) => {})
    );
  }

  getLatNLong = (address: any) => {
    const url = `${environment.referralServices}/v1/google/map/address/${address}`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: any) => {})
    );
  };

  getAddress = (lat: any, lang: any) => {
    const url = `${environment.referralServices}/v1/google/map/latLang/${lat}/${lang}`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: any) => {})
    );
  };

  handleError(error: HttpErrorResponse): Observable<any> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
