import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { pageSizeOptions } from '@app/appConstants';
import {
  AudienceActionResponse,
  AudienceDetailResponse,
  AudienceGridResponse,
  AudienceMerchantResponse,
  AudienceOverviewDataResponse,
  AudienceReq,
  AudienceSaveSuccessResponse,
  AudienceSummaryReviewResponse,
  CampaignsResponse,
  DownloadAudienceReportResponse,
  DrillDataResponse,
  Expression,
  LoadAudienceResponse,
  MapResponse,
} from '@app/model/interfaces/audience';
import { UserInfoService } from '@app/shared/services/user-info.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { audienceDefaultPayload } from './audienceConstants';

@Injectable({
  providedIn: 'root',
})
export class AudienceService {
  private payload: AudienceReq = { ...audienceDefaultPayload };
  private selectedMenu = 'location';
  private filterUpdatedAfterLoadAudience = false;

  private audienceId: number | null = null;
  private bankId: number | null = null;
  private keepFilter = false;

  audienceFilterValueChanged: EventEmitter<string> = new EventEmitter<string>();
  constructor(private http: HttpClient, private userService: UserInfoService) {}

  getAudiencePayload(): AudienceReq {
    return this.payload;
  }

  setAudiencePayload(): void {
    this.payload = { ...audienceDefaultPayload };
  }

  setAudienceId(aid: number | null): void {
    this.audienceId = aid;
  }
  setKeepFilter(val: boolean = false): void {
    this.keepFilter = val;
  }
  getKeepFilter(): boolean {
    return this.keepFilter;
  }
  getBankId(): number | null {
    return this.bankId;
  }

  setBankId(bankId: number): void {
    this.bankId = bankId;
  }

  getAudienceId(): number | null {
    return this.audienceId;
  }
  getSelectedMenu = () => this.selectedMenu;

  setSelectedMenu = (name: string) => {
    this.selectedMenu = name;
    this.audienceFilterValueChanged.emit();
  }

  setAudienceFilter(filters: Expression = {}): void {
    this.setFilterUpdatedAfterLoadAudience(true);
    this.payload.filters = filters;
    this.audienceFilterValueChanged.emit();
  }

  getAudienceFilter(): Expression {
    return this.payload.filters || {};
  }

  resetAudienceFilter(): void {
    this.payload = {
      page: 0,
      recordsPerPage: pageSizeOptions[0],
      sortBy: 'potentialRevenue',
      sortOrder: 'DESC',
      filters: {},
    };
    this.setFilterUpdatedAfterLoadAudience(true);
    this.audienceFilterValueChanged.emit();
  }

  setAudiencePayloadPage(page: number): void {
    this.payload.page = page;
  }

  getFilterUpdatedAfterLoadAudience = (): boolean =>
    this.filterUpdatedAfterLoadAudience

  setFilterUpdatedAfterLoadAudience(isUpdated: boolean): void {
    this.filterUpdatedAfterLoadAudience = isUpdated;
  }

  setAudiencePayloadRecordsPerPage(recordsPerPage: number): void {
    this.payload.recordsPerPage = recordsPerPage;
  }
  setAudiencePayloadSortBy(sortBy: string): void {
    this.payload.sortBy = sortBy;
  }
  setAudiencePayloadSortOrder(sortOrder: string): void {
    this.payload.sortOrder = sortOrder;
  }

  getMapData = (
    bankId: number,
    payload: AudienceReq
  ): Observable<MapResponse> => {
    const url = `${environment.referralServices}/v1/bank/${bankId}/audience/heatmap`;
    return this.http.post(url, payload).pipe(
      catchError(this.handleError),
      tap((response: MapResponse) => {})
    );
  }

  getAudienceList = (
    bankId: number,
    payload: AudienceReq
  ): Observable<LoadAudienceResponse> => {
    const url = `${environment.referralServices}/v1/bank/${bankId}/audience/?pageNum=${payload.pageIndex}&pageSize=${payload.pageSize}&sortBy=${payload.sortBy}&sortOrder=${payload.sortOrder}`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: LoadAudienceResponse) => {})
    );
  }

  getAudienceAction = (
    bankId: number,
    id: number
  ): Observable<AudienceActionResponse> => {
    const url = `${
      environment.referralServices
    }/v1/bank/${bankId}/audience/action?audienceId=${id ? id : ''}`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: AudienceActionResponse) => {})
    );
  }
  getAudienceDetailByAudienceId = (
    bankId: number,
    audienceId: number
  ): Observable<AudienceDetailResponse> => {
    const url = `${environment.referralServices}/v1/bank/${bankId}/audience/${audienceId}`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: AudienceDetailResponse) => {})
    );
  }

  getAudienceDetails = (
    bankId: number,
    id: number
  ): Observable<AudienceDetailResponse> => {
    const url = `${environment.referralServices}/v1/bank/${bankId}/audience/${id}`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: AudienceDetailResponse) => {})
    );
  }

  getAudienceMerchantReportGrid(
    payload: AudienceReq,
    bankId: number,
    aId: string
  ): Observable<AudienceMerchantResponse> {
    return this.http
      .post(
        environment.referralServices + `/v1/bank/${bankId}/audience/report${aId}`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap((response: AudienceMerchantResponse) => {})
      );
  }
  getAudienceOverviewData = (bankId: number, payload: AudienceReq) => {
    return this.http
      .post(
        `${environment.referralServices}/v1/bank/${bankId}/audience/overview`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap((response: AudienceOverviewDataResponse) => {})
      );
  }

  getAudienceReportGrid(
    payload: AudienceReq,
    bankId: number,
    type: string
  ): Observable<AudienceGridResponse> {
    return this.http
      .post(
        `${environment.referralServices}/v1/bank/${bankId}/audience/report/${type}`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap((response: AudienceGridResponse) => {})
      );
  }

  getDrillData = (
    bankId: number,
    payload: AudienceReq
  ): Observable<DrillDataResponse> => {
    const url = `${environment.referralServices}/v1/bank/${bankId}/audience/summary/`;
    return this.http.post(url, payload).pipe(
      catchError(this.handleError),
      tap((response: DrillDataResponse) => {})
    );
  }

  getCampaignList = (bankId: number): Observable<CampaignsResponse> => {
    const url = `${environment.referralServices}/v1/bank/${bankId}/campaign`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: CampaignsResponse) => {})
    );
  }

  getAudienceSummaryReview = (
    bankId: number,
    payload: AudienceReq
  ): Observable<AudienceSummaryReviewResponse> => {
    const url = `${environment.referralServices}/v1/bank/${bankId}/audience/summary/review`;
    return this.http.post(url, payload).pipe(
      catchError(this.handleError),
      tap((response: AudienceSummaryReviewResponse) => {})
    );
  }

  saveAudience = (
    bankId: number,
    payload: AudienceReq,
    aid?: number | null
  ): Observable<AudienceSaveSuccessResponse> => {
    const path = aid ? `/${aid}` : ``;
    const url = `${environment.referralServices}/v1/bank/${bankId}/audience${path}`;
    return this.http.post(url, payload).pipe(
      catchError(this.handleError),
      tap((response: AudienceSaveSuccessResponse) => {})
    );
  }

  actionAudience = (
    action: string,
    aid: number | null
  ): Observable<AudienceActionResponse> => {
    const url = `${environment.referralServices}/v1/audience/${action}/${aid}`;
    return this.http.post(url, {}).pipe(
      catchError(this.handleError),
      tap((response: AudienceActionResponse) => {})
    );
  }

  downloadAudienceReport = (
    bankId: number
  ): Observable<DownloadAudienceReportResponse> => {
    const url = `${environment.referralServices}/v2/bank/${bankId}/audience/download`;
    const payload = {
      ...this.getAudiencePayload(),
      audienceId: this.audienceId,
      userId: this.userService.getUserId(),
    };
    payload.sortBy = '';
    payload.sortOrder = '';
    return this.http.post(url, payload).pipe(
      catchError(this.handleError),
      tap((response: DownloadAudienceReportResponse) => {})
    );
  }

  handleError(error: HttpErrorResponse): Observable<any> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
