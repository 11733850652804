<a
  class="notificationDropDown d-inline-block p-1 pe-3"
  title="Notification Center"
  id="messagesDropdown"
  role="button"
  data-toggle="dropdown"
  aria-haspopup="true"
  aria-expanded="false"
  (click)="openNotification()"
>
  <img class="img-cl" src="assets/images/icons/icon_bell.svg" />
  <span *ngIf="unreadNotification" class="badge-circle"></span>
</a>
<section *ngIf="isopenNotifiaction" class="dropdown-list">
  <div
    class="messageWrapperBlock shadow border"
    *ngIf="notificationResultList.length === 0 && !showloader"
  >
    <div class="messageWrapperBlock m-3 fs-14">
      {{ 'notifications.nonotification' | translate }}
    </div>
  </div>
  <div
    class="messageWrapperBlock shadow border"
    *ngIf="showloader || notificationResultList.length > 0"
  >
    <div class="pt-2">
      <div class="positionRelative p-3" *ngIf="showloader">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
      <clover-all-notifications-dialog
        [isPopup]="isPopup"
        *ngIf="notificationResultList.length > 0 && !showloader"
        [totalCount]="
          hasMoaPriviledge
            ? notificationResultList.length
            : notificationResultList.length - moaList.length
        "
        [moaList]="slicedMoaList"
        [moaListCount]="moaList.length"
        [hasMoaPriviledge]="hasMoaPriviledge"
        [nonMoaList]="nonMoaList"
        [notificationActionList]="notificationActionList"
        [notificationResultList]="notificationResultList"
        [notificationDownLaodList]="notificationDownLaodList"
      ></clover-all-notifications-dialog>
    </div>
  </div>
</section>
