export const riskAlertsViewGridDefaultColumn = [
  {
    dataFieldName: 'createdDateTime',
    displayName: 'Alert Date',
    isSortable: true,
    hasFilter: true,
    fieldType: 'date',
    multiLanguageKey: 'riskalertsTableColumnName.alertDate'
  },
  {
    dataFieldName: 'riskType',
    displayName: 'Risk Type',
    isSortable: true,
    hasFilter: true,
    fieldType: 'multi',
    multiLanguageKey: 'riskalertsTableColumnName.riskType'
  },
  {
    dataFieldName: 'openRisk',
    displayName: 'Open Risk',
    isSortable: true,
    hasFilter: true,
    fieldType: 'multi',
    multiLanguageKey: 'riskalertsTableColumnName.openRisk'
  },
  {
    dataFieldName: 'transactionAmount',
    displayName: 'Transaction Amount',
    isSortable: true,
    hasFilter: true,
    fieldType: 'range',
    multiLanguageKey: 'riskalertsTableColumnName.transactionAmount'
  },
  {
    dataFieldName: 'fundingAmount',
    displayName: 'Funding Amount',
    isSortable: true,
    hasFilter: true,
    fieldType: 'range',
    multiLanguageKey: 'riskalertsTableColumnName.fundingAmount'
  },
  {
    dataFieldName: 'status',
    displayName: 'Status',
    isSortable: true,
    hasFilter: true,
    fieldType: 'multi',
    multiLanguageKey: 'shared.status'
  }
];

export const alertHistoryStatusTableGrid = [
  {
    dataFieldName: 'status',
    displayName: 'Status Update',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'prospect.statusUpdate.yourlist.title'
  },
  {
    dataFieldName: 'createdDateTime',
    displayName: 'Date/Time',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'riskalertsTableColumnName.dateTime'
  }
];

export const callLogHistoryTableGrid = [
  {
    dataFieldName: 'numberDialed',
    displayName: 'Number Dialed',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'riskAlerts.commLogsTableColumns.numberDialed'
  },
  {
    dataFieldName: 'callNotes',
    displayName: 'Call Notes',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'riskAlerts.commLogsTableColumns.callNotes'
  },
  {
    dataFieldName: 'callDurationInSeconds',
    displayName: 'Call Duration',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'riskAlerts.commLogsTableColumns.callDuration'
  },
  {
    dataFieldName: 'triggerDateTime',
    displayName: 'Date | Time',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'riskAlerts.commLogsTableColumns.dateTime'
  }
];

export const emailLogHistoryTableGrid = [
  {
    dataFieldName: 'recipient',
    displayName: 'Recipient Email',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'riskAlerts.commLogsTableColumns.recipientEmail'
  },
  {
    dataFieldName: 'from',
    displayName: 'From Email',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'riskAlerts.commLogsTableColumns.fromEmail'
  },
  {
    dataFieldName: 'triggerDateTime',
    displayName: 'Date | Time',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'riskAlerts.commLogsTableColumns.dateTime'
  }
];

export const smsLogHistoryTableGrid = [
  {
    dataFieldName: 'recipientPhone',
    displayName: 'Recipient Phone Number',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'riskAlerts.commLogsTableColumns.recipientPhoneNumber'
  },
  {
    dataFieldName: 'content',
    displayName: 'Content',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'riskAlerts.commLogsTableColumns.content'
  },
  {
    dataFieldName: 'status',
    displayName: 'Current Status',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'riskAlertDetails.currentStatus'
  },
  {
    dataFieldName: 'type',
    displayName: 'Type',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'shared.common.type'
  },
  {
    dataFieldName: 'triggerDateTime',
    displayName: 'Date | Time',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'riskAlerts.commLogsTableColumns.dateTime'
  }
];

export const writtenLogHistoryTableGrid = [
  {
    dataFieldName: 'transmissionDateTime',
    displayName: 'Date',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'shared.common.date'
  },
  {
    dataFieldName: 'createdDateTime',
    displayName: 'Time',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'shared.time'
  },
  {
    dataFieldName: 'postalTracking',
    displayName: 'Postal Tracking #',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'riskAlerts.commLogsTableColumns.postalTrackingNum'
  }
];

export const riskAlertsBatchReportTableGrid = [
  {
    dataFieldName: 'mid',
    displayName: 'MID',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'opportunityTableColumnName.mid'
  },
  {
    dataFieldName: 'accountName',
    displayName: 'Merchant Name',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'opportunityTableColumnName.merchantName'
  }
];

export const multiFilterOptions = {
  riskType: [
    { code: 'FUNDING', desc: 'FUNDING' },
    { code: 'PTS', desc: 'Paperless Transaction System (PTS)' },
    { code: 'RMI', desc: 'Risk Monitoring Investigations (RMI)' },
    { code: 'NACHAHOLD', desc: 'NACHAHOLD' }
  ],
  status: [
    { code: 'FUNDING REVIEW PENDING', desc: 'FUNDING REVIEW PENDING' },
    { code: 'APPROVED FOR NEXT FUNDING CYCLE', desc: 'APPROVED FOR NEXT FUNDING CYCLE' },
    { code: 'HELD IN RESERVE', desc: 'HELD IN RESERVE' },
    { code: 'NEW NACHA HOLD', desc: 'NEW NACHA HOLD' },
    { code: 'EXCLUDED FROM PROCESSING', desc: 'EXCLUDED FROM PROCESSING' }
  ],
  openRisk: [
    { code: 'YES', desc: 'YES' },
    { code: 'NO', desc: 'NO' }
  ]
};

export const riskTypeCodes = {
  FUNDING: 'FUNDING',
  PTS: 'PTS',
  RMI: 'RMI',
  NACHAHOLD: 'NACHAHOLD'
};

const commonStaticContent = {
  somethingApiError: 'something.wrong',
  cancel: 'common.button.text.cancel',
  removeButton: 'shared.common.text.remove',
  deleteButton: 'shared.common.text.delete',
  confirm: 'shared.common.text.confirm',
};

export const RiskAlertsStaticContent = {
  ...commonStaticContent,
  successMessage: 'prospect.status.update.successfully',
  statusUpdateSuccess: 'prospects.status.success.message',
  successTitle: 'upload.referral.sheet.dialog.success'
};

export const riskAlertsNotificationTypes = {
  RISK_ALERTS_ISSUED: 'RISK_ALERTS_ISSUED',
  RISK_ALERTS_UPDATED: 'RISK_ALERTS_UPDATED',
  RISK_ALERTS_COMMUNICATION_RECEIVED: 'RISK_ALERTS_COMMUNICATION_RECEIVED'
};

export const riskAlertsHeadingContent = 
[
  { notificationType: 'RISK_ALERTS_ISSUED', headingText: 'riskAlerts.notifications.alertsIssued' },
  { notificationType: 'RISK_ALERTS_UPDATED', headingText: 'riskAlerts.notifications.alertsStatusUpdate' },
  { notificationType: 'RISK_ALERTS_COMMUNICATION_RECEIVED', headingText: 'riskAlerts.notifications.alertsCommunication' }

];

export const riskAlertsBatchHeadingContent = 
[
  { notificationType: 'RISK_ALERTS_ISSUED', headingText: 'riskAlerts.batchNotifications.alertsIssued' },
  { notificationType: 'RISK_ALERTS_UPDATED', headingText: 'riskAlerts.batchNotifications.alertsStatusUpdate' },
  { notificationType: 'RISK_ALERTS_COMMUNICATION_RECEIVED', headingText: 'riskAlerts.batchNotifications.alertsCommunication' }

];
