import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToasterComponent } from '../component/toaster/toaster.component';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private types: string[] = [
    'success',
    'danger',
    'warning',
    'info',
    'primary',
    'secondary',
    'dark',
    'light',
  ];
  constructor(private snackBar: MatSnackBar) {}

  success(message: string, title?: string): void {
    this.showToast('success', message, title);
  }

  error(message: string, title?: string): void {
    this.showToast('error', message, title);
  }

  info(message: string, title?: string): void {
    this.showToast('info', message, title);
  }

  primary(message: string, title?: string): void {
    this.showToast('primary', message, title);
  }

  showToast(type: string, msg: string, title?: string): void {
    this.snackBar.openFromComponent(ToasterComponent, {
      duration: 5000,
      panelClass: [type],
      horizontalPosition: 'right',
      verticalPosition: 'top',
      data: { msg },
    });
  }
}
