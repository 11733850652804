import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CallActionResponse, MerchantCallInfo, MerchantCallInfoResponse, RecentTransferCallResponse, TransferCallInfo, TransferCallInfoRequest, TransferCallInfoResponse } from '@app/model/interfaces/call';
import {
  BusinessConsultantInfo,
  BusinessConsultantsListResponse,
} from '@app/model/interfaces/user-management';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CallService {
  merchantCallInfo!: MerchantCallInfo;
  transferCallInfo!: TransferCallInfo;
  businessConsultant!: BusinessConsultantInfo[];
  callInProgress!: boolean;
  constructor(private http: HttpClient) {}

  getCallActiveStatus = (): boolean => this.callInProgress;

  setCallActiveStatus = (callInProgress: boolean) => {
    this.callInProgress = callInProgress;
  };

  getTransferCallInfo = () => this.transferCallInfo;

  setTransferCallInfo = (transferCallInfo: TransferCallInfo) => {
    this.transferCallInfo = transferCallInfo;
  };

  getMerchantCallInfo = () => this.merchantCallInfo;

  setMerchantCallInfo = (merchantCallInfo: MerchantCallInfo) => {
    this.merchantCallInfo = merchantCallInfo;
  };

  getBusinessConsultant(): BusinessConsultantInfo[] {
    return this.businessConsultant;
  }

  setBusinessConsultant(bc: BusinessConsultantInfo[]): void {
    this.businessConsultant = bc;
  }

  voiceCall = (
    moduleName: string,
    id: number
  ): Observable<MerchantCallInfoResponse> => {
    const url = `${environment.salesRegimeServices}/v1/${moduleName}/${id}/voice/calls`;
    return this.http.post(url, {}).pipe(
      catchError(this.handleError),
      tap((response: MerchantCallInfoResponse) => {})
    );
  };

  endCall = (callSid: string): Observable<CallActionResponse> => {
    const url = `${environment.salesRegimeServices}/v1/voice/endcall/${callSid}`;
    return this.http.post(url, {}).pipe(
      catchError(this.handleError),
      tap((response: CallActionResponse) => {})
    );
  };

  createActivity(
    moduleName: string,
    id: number,
    payload: any
  ): Observable<any> {
    return this.http
      .post(
        `${environment.salesRegimeServices}/v1/${moduleName}/${id}/activity/call`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  getBusinessConsultantList(): Observable<BusinessConsultantsListResponse> {
    return this.http
      .get<BusinessConsultantsListResponse>(
        `${environment.salesRegimeServices}/v1/list/bcUsers`
      )
      .pipe(catchError(this.handleError));
  }


  getRecentCallDetails(userId: string): Observable<RecentTransferCallResponse> {
    return this.http
      .get<RecentTransferCallResponse>(
        `${environment.salesRegimeServices}/v1/list/recentCallTransfers/${userId}`
      )
      .pipe(catchError(this.handleError));
  }

  callTransfer(entityType: string, entityId: string, twilioMappingId: number,
    payload: TransferCallInfoRequest
  ): Observable<TransferCallInfoResponse> {
    return this.http
      .post(
        `${environment.salesRegimeServices}/v1/${entityType}/${entityId}/${twilioMappingId}/voice/callTransfer`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  updateCallAction(conferenceSid: string, callSid: string, action: string, value: boolean): Observable<CallActionResponse> {
    return this.http
      .post(
        `${environment.salesRegimeServices}/v1/voice/updateParticipant/${conferenceSid}/${callSid}/${action}/${value}`,
        {}
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  handleError(error: HttpErrorResponse): Observable<any> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
