<div class="dialog-container">
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <h2 class="section-title">
        Select Your Quick Text
        <mat-spinner *ngIf="isLoading" diameter="40"></mat-spinner>
      </h2>
      <div class="searchInput">
        <input
          (keyup)="onChangeEvent($event)"
          *ngIf="!isLoading"
          class="border rounded p-1 px-2"
          placeholder="Search Quick Text"
          type="text"
        />
        <img src="assets/images/icons/icon_search.svg" />
      </div>
    </div>
  </div>
  <div
    class="dialog-content pb-0"
    *ngIf="!isLoading && quickTextData.length > 0"
  >
    <div class="row border rounded mx-1 mb-2">
      <div class="col-md-3 leftTabPanel p-0 border-end">
        <div
          *ngFor="let item of quickTextData; let i = index"
          class="pointer p-2 d-flex justify-content-between"
          [class.border-bottom]="i + 1 !== quickTextData.length"
          [class.activeTab]="i + 1 === selectedIndex"
          (click)="getQuickText(item, i + 1)"
        >
          <span class="spanTextEllipsis">{{ item.textName }}</span>
        </div>
      </div>
      <div class="col-md-9 p-3">
        {{ quickText }}
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button class="edit-btn me-3" (click)="onCancel()">{{'common.button.text.cancel' | translate }}</button>
      <button
        [disabled]="!quickText"
        [ngClass]="{
          disabled: !quickText
        }"
        (click)="onInsert()"
      >
        Insert
      </button>
    </div>
  </div>
  <clover-no-data
    *ngIf="!isLoading && quickTextData.length === 0"
    message="{{ 'quickText.NotAvailable' | translate }}"
  ></clover-no-data>
</div>
