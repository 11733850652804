import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  evtSource!: EventSource;
  updateUnReadMsgCountEvent = new BehaviorSubject('');
  updateConversationListEvent = new BehaviorSubject('');
  constructor(private http: HttpClient) { }

  getListOfOpenConversations(userId: string): Observable<any> {
    return this.http
      .get<any>(`${environment.salesRegimeServices}/v1/user/getListOfOpenConversations/TEXTS/${userId}`)
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }
  getNewConversationUserList(userId: string): Observable<any> {
    return this.http
      .get<any>(`${environment.salesRegimeServices}/v1/user/getNamesForNewConversation/${userId}`)
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  getMessages(
    contextEntityType: string,
    contextEntityId: number,
    targetEntityType: string,
    twilioMappingId: number,
    activityId: number
  ): Observable<string | number> {
    return this.http
      .get<string | number>(
        `${environment.salesRegimeServices}/v1/messages/texts/${contextEntityType}/${contextEntityId}/${targetEntityType}?twilioMappingId=${twilioMappingId}&activityId=${activityId}`
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }


  sendMessage(
    contextEntityType: string,
    contextEntityId: number,
    targetEntityType: string,
    message: string
  ): Observable<string | number> {
      const payload = { message };
      const url = `${environment.salesRegimeServices}/v1/messages/texts/${contextEntityType}/${contextEntityId}/${targetEntityType}`;
      return this.http.post(url, payload).pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }


  handleError(error: HttpErrorResponse): Observable<any> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
