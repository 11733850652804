import { BreadcrumbsResponse } from '../../model/interfaces/opportunities';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AccountListResponse,
  MyAccountContact,
  MyAccountContactResponse,
  MyAccountDetails,
  MyAccountDetailsResponse,
  MyAccountHeirarchyResponse,
  MyAccountListResponse,
  MyAccountRequest,
  MyAccountSummaryResponse,
  MyAccountTeamResponse,
  ProductListResponse,
} from '@app/model/interfaces/my-accounts';
import {
  PaginatorRiskAlertsPayLoad,
  RiskAlertsListResponse,
  RiskAlertTypeListResponse,
  RiskAlertStatusListResponse,
  RiskAlertsCommunicationLogsResponse,
  RiskAlertsBatchReportResponse
} from '@app/model/interfaces/riskalerts';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MyAccountsService {
  refreshProspectiveTask = new BehaviorSubject('');
  refreshContactList = new BehaviorSubject('');
  constructor(private http: HttpClient) {}

  myAccountDetails!: MyAccountDetails;
  myAccountContact!: MyAccountContact[];
  myAccountId!: number;

  getMyAccountId = () => {
    return this.myAccountId;
  };

  setMyAccountId = (myAccountId: number) => {
    this.myAccountId = myAccountId;
  };

  getMyAccountDetails = () => {
    return this.myAccountDetails;
  };

  setMyAccountDetails = (myAccountDetails: MyAccountDetails) => {
    this.setMyAccountId(myAccountDetails.id)
    this.myAccountDetails = myAccountDetails;
  };

  getMyAccountContact = () => {
    return this.myAccountContact;
  };

  setMyAccountContact = (myAccountContact: MyAccountContact[]) => {
    this.myAccountContact = myAccountContact;
  };

  getSummary(payload: MyAccountRequest): Observable<MyAccountSummaryResponse> {
    return this.http
      .post<MyAccountSummaryResponse>(
        `${environment.accountServices}/v1/account/list/summary`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  getLists(payload: MyAccountRequest): Observable<MyAccountListResponse> {
    return this.http
      .post<MyAccountListResponse>(
        `${environment.accountServices}/v1/account/list`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  getAccountHeirarchy(id: number): Observable<MyAccountHeirarchyResponse> {
    const url = `${environment.accountServices}/v1/account/${id}/heirarchy`;
    return this.http.get<MyAccountHeirarchyResponse>(url).pipe(
      catchError(this.handleError),
      tap(() => {})
    );
  }

  getAccountDetails(id: number): Observable<MyAccountDetailsResponse> {
    const url = `${environment.accountServices}/v2/account/${id}`;
    return this.http.get<MyAccountDetailsResponse>(url).pipe(
      catchError(this.handleError),
      tap(() => {})
    );
  }

  getAccountTeam(id: number): Observable<MyAccountTeamResponse> {
    const url = `${environment.accountServices}/v1/account/${id}/accountteam`;
    return this.http.get<MyAccountTeamResponse>(url).pipe(
      catchError(this.handleError),
      tap(() => {})
    );
  }

  getAccountContact(id: number): Observable<MyAccountContactResponse> {
    const url = `${environment.accountServices}/v1/account/${id}/contact`;
    return this.http.get<MyAccountContactResponse>(url).pipe(
      catchError(this.handleError),
      tap(() => {})
    );
  }

  getOpportunityContact(id: number): Observable<MyAccountContactResponse> {
    const url = `${environment.accountServices}/v1/account/${id}/opportunity/contact`;
    return this.http.get<MyAccountContactResponse>(url).pipe(
      catchError(this.handleError),
      tap(() => {})
    );
  }

  addAccountContact(id: number, payload: any): Observable<MyAccountContactResponse> {
    return this.http
      .post<MyAccountListResponse>(
        `${environment.accountServices}/v1/account/${id}/contact`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  updateAccountContact(id: number, contactId: number, payload: any): Observable<MyAccountContactResponse> {
    return this.http
      .post<MyAccountListResponse>(
        `${environment.accountServices}/v1/account/${id}/contact/${contactId}`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  accountContactAction(id: number, contactId: number,  payload: { action: string }): Observable<MyAccountContactResponse> {
    return this.http
      .post<MyAccountListResponse>(
        `${environment.accountServices}/v1/account/${id}/contact/${contactId}/action`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  getProductList(): Observable<ProductListResponse> {
    const url = `${environment.accountServices}/v1/account/${this.myAccountDetails.id}/product`;
    return this.http.get<ProductListResponse>(url).pipe(
      catchError(this.handleError),
      tap(() => {})
    );
  }

  getAccountList(searchTxt: string): Observable<AccountListResponse> {
    const url = `${environment.accountServices}/v1/account/list/${searchTxt}`;
    return this.http.get<ProductListResponse>(url).pipe(
      catchError(this.handleError),
      tap(() => {})
    );
  }
  
  getRiskAlertsList(accountId: number, payload: PaginatorRiskAlertsPayLoad): Observable<RiskAlertsListResponse> {
    return this.http
      .post<RiskAlertsListResponse>(
        `${environment.accountServices}/v1/account/${accountId}/riskAlerts`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  getRiskAlertTypeList(): Observable<RiskAlertTypeListResponse> {
    return this.http.get<RiskAlertTypeListResponse>(
      `${environment.accountServices}/v1/riskAlertTypeList`)
      .pipe(
        catchError(this.handleError),
        tap(() => {})
    );
  }

  getRiskAlertStatusList(payload: PaginatorRiskAlertsPayLoad | null | {}): Observable<RiskAlertStatusListResponse> {
    return this.http
      .post<RiskAlertStatusListResponse>(
        `${environment.accountServices}/v1/riskAlertStatusList`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  getRiskAlertCommunicationLogs(accountId: number): Observable<RiskAlertsCommunicationLogsResponse> {
    return this.http.get<RiskAlertsCommunicationLogsResponse>(
      `${environment.accountServices}/v1/account/${accountId}/riskAlertCommunicationLogs`)
      .pipe(
        catchError(this.handleError),
        tap(() => {})
    );
  }

  getRiskAlertsBatchReport(entityId: number | undefined, payload: null | {}): Observable<RiskAlertsBatchReportResponse> {
    return this.http
      .post<RiskAlertsBatchReportResponse>(
        `${environment.salesRegimeServices}/v1/notification/riskAlertsReport/${entityId}`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  handleError(error: HttpErrorResponse): Observable<any> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
