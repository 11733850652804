<form autocomplete="disable">
  <div class="filterWrapper" *ngIf="!dropDown && !datefilter && !rangefilter">
    <mat-form-field class="cloverInput bottomSpaceZero" appearance="fill">
      <mat-label
        >{{ 'shared.search' | translate }} {{ placeholderInput }}</mat-label
      >
      <input
        type="text"
        matInput
        [formControl]="myControl"
        (keydown.enter)="onApply($event)"
        (keydown)="onKeyDown($event)"
        autocomplete="disable"
      />
    </mat-form-field>
    <mat-error *ngIf="!isValidNumber">
      {{ 'shared.percentage.error' | translate }}</mat-error
    >

    <div class="d-flex justify-content-between">
      <button class="edit-btn" (click)="onCancel($event)">
        {{ 'shared.clear' | translate }}
      </button>
      <button
        type="button"
        class="primary-background-color primary-border-color"
        (click)="onApply($event)"
      >
        {{ 'shared.apply' | translate }}
      </button>
    </div>
  </div>
  <div class="filterWrapper" *ngIf="rangefilter">
    <mat-form-field
      class="cloverInput bottomSpaceZero rangeFilter mb-3"
      appearance="fill"
    >
      <mat-label>{{ 'filter.greaterthanOrEqual' | translate }} </mat-label>
      <input
        type="text"
        matInput
        [formControl]="lte"
        (keydown.enter)="onApply($event)"
        (keydown)="onKeyDown($event, 'gte')"
        autocomplete="disable"
      />
    </mat-form-field>
    <mat-form-field
      class="cloverInput bottomSpaceZero rangeFilter mb-3"
      appearance="fill"
    >
      <mat-label>{{ 'filter.lessthanOrEqual' | translate }} </mat-label>
      <input
        type="text"
        matInput
        [formControl]="gte"
        (keydown.enter)="onApply($event)"
        (keydown)="onKeyDown($event, 'lte')"
        autocomplete="disable"
      />{{ isGreaterLessError }}
    </mat-form-field>
    <mat-error class="mb-2" *ngIf="isGreaterLessError">
      {{ 'greaterLessError' | translate }}
    </mat-error>
    <mat-error class="mb-2" *ngIf="!isValidNumber">
      {{ 'shared.percentage.error' | translate }}</mat-error
    >

    <div class="d-flex justify-content-between">
      <button class="edit-btn" (click)="onCancel($event)">
        {{ 'shared.clear' | translate }}
      </button>
      <button
        type="button"
        class="primary-background-color primary-border-color"
        (click)="onApply($event)"
      >
        {{ 'shared.apply' | translate }}
      </button>
    </div>
  </div>
  <div
    class="filterWrapper selectOption"
    appearance="fill"
    *ngIf="dropDown"
    style="visibility: hidden"
  >
    <mat-select #mySelector [formControl]="myControl" multiple>
      <mat-option
        *ngFor="let val of filteredOptions; let i = index"
        [value]="val.code"
      >
        <span *ngIf="!val.isRating"
          >{{
            !('shared.' + val.desc | translate).startsWith('shared')
              ? ('shared.' + val.desc | translate)
              : val.desc
          }}
        </span>
        <span *ngIf="val.isRating">
          <span *ngFor="let rating of [1, 2, 3]">
            <img
              class="rating-image"
              *ngIf="rating <= val.desc"
              src="assets/images/icons/icon_fire_filled.svg"
            />
            <img
              class="rating-image"
              *ngIf="rating > val.desc"
              src="assets/images/icons/icon_fire_outline.svg"
            />
          </span>
        </span>
      </mat-option>
      <div class="d-flex p-1 justify-content-between">
        <button class="edit-btn" (click)="onCancel($event)">
          {{ 'shared.clear' | translate }}</button
        ><button
          type="button"
          [class.disabled]="
            !myControl.value ||
            (myControl && myControl.value && !myControl.value.length)
          "
          [disabled]="
            !myControl.value ||
            (myControl && myControl.value && !myControl.value.length)
          "
          (click)="onApply($event)"
        >
          {{ 'shared.apply' | translate }}
        </button>
      </div>
    </mat-select>
  </div>
  <mat-form-field appearance="fill" *ngIf="datefilter">
    <mat-date-range-input [formGroup]="customDate" [rangePicker]="picker">
      <input
        matStartDate
        formControlName="start"
        placeholder="Start date"
        autocomplete="disable"
      />
      <input
        matEndDate
        formControlName="end"
        placeholder="End date"
        autocomplete="disable"
      />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker>
      <mat-date-range-picker-actions class="d-flex justify-content-between">
        <button
          matDateRangePickerCancel
          class="edit-btn"
          (click)="onCancel($event)"
        >
          {{ 'shared.clear' | translate }}
        </button>
        <button (click)="onApply($event)" matDateRangePickerApply>
          {{ 'shared.apply' | translate }}
        </button>
      </mat-date-range-picker-actions>
    </mat-date-range-picker>
  </mat-form-field>
</form>
