import { Injectable } from '@angular/core';
import { Bank } from '@app/model/interfaces/menu';
import { DataSharingService } from './data-sharing.service';

@Injectable({
  providedIn: 'root',
})
export class BankInfoService {
  private selectedBank!: Bank;
  private banksList: Bank[] = [];
  constructor(private dataSharing: DataSharingService) {}

  getBankId = (): number => this.selectedBank?.id;

  getBankName = (): string => this.selectedBank?.name;

  getBankCountry = (): string => this.selectedBank?.country;

  showBankProcessingVolume = (): boolean =>
    this.selectedBank?.processingVolumeEnabled;

  getBanksList(): Bank[] {
    return this.banksList;
  }
  setBanksList(banksList: Bank[]): void {
    this.banksList = banksList;
  }
  checkMOAEnabledBank = (): boolean => this.selectedBank?.moaEnabled;

  setBankId(bank: Bank): void {
    if (this.selectedBank?.id !== bank.id) {
      this.selectedBank = { ...bank };
      this.dataSharing.bankIdChanged.next(this.selectedBank.id);
    }
  }
}