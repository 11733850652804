<ul [class.expandMenu]="expandMenu">
  <ng-container *ngFor="let menu of menuList">
    <li
      routerLinkActive="active-link"
      #rla_overview1="routerLinkActive"
      *ngIf="menu.access"
      title="{{ menu.label | translate }}"
      class="d-flex align-items-center justify-content-between"
    >
      <a
        routerLink="{{ menu.url }}"
      >
        <img
          src="assets/images/nav_images/{{
            rla_overview1.isActive ? 'nav_on_' : 'nav_'
          }}{{ menu.image }}.svg"
        /><span *ngIf="expandMenu">{{ menu.label | translate }}</span>
      </a>
      <button
        class="menu-link-btn"
        *ngIf="menu.activateUrl != null"
        (click)="menuChanged(menu.activateUrl)"
      >
        {{ menu.activateUrl }}
      </button>
      <span
        *ngIf="menu.showCount && menu.count"
        class="count pointer"
        [class.nonExpand]="!expandMenu"
        routerLink="{{ menu.url }}"
        [class.smooth]="menu.count > 99"
      >
        {{ menu.count > 99 ? '99+' : menu.count }}
      </span>
    </li>
  </ng-container>
</ul>
<ul class="positionAbsolute bottom-0" [class.end-0]="expandMenu">
  <li class="expandMenuBlock">
    <span class="linkIcon pointer" (click)="toggleExpandMenu()"
      ><img
        [class.rotateIcon]="expandMenu"
        src="assets/images/nav_images/nav_arrowIcon.svg"
    /></span>
  </li>
</ul>
