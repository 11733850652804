import {
  AfterViewInit,
  Directive,
  Host,
  Input,
  Optional,
  Renderer2,
  Self,
  ViewContainerRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatButton } from '@angular/material/button';
import { PageObject } from '@app/model/interfaces/referral-activity';

@Directive({
  selector: '[cloverStylePaginator]',
})
export class StylePaginatorDirective implements AfterViewInit {
  private pageGapTxt = '...';
  private rangeStart!: number;
  private rangeEnd!: number;
  private buttons: MatButton[] = [];
  private curPageObj: PageObject = {
    length: 0,
    pageIndex: 0,
    pageSize: 0,
    previousPageIndex: 0,
  };

  @Input()
  get showTotalPages(): number {
    return this.showTotalPage;
  }
  set showTotalPages(value: number) {
    this.showTotalPage = value % 2 === 0 ? value + 1 : value;
  }
  private showTotalPage = 1;

  get inc(): number {
    return this.showTotalPage % 2 === 0
      ? this.showTotalPages / 2
      : (this.showTotalPages - 1) / 2;
  }

  get numOfPages(): number {
    return this.matPag.getNumberOfPages();
  }

  get lastPageIndex(): number {
    return this.matPag.getNumberOfPages() - 1;
  }

  constructor(
    @Host() @Self() @Optional() private readonly matPag: MatPaginator,
    private vr: ViewContainerRef,
    private ren: Renderer2
  ) {
    // to re_render buttons on items per page change and first, last, next and prior buttons
    this.matPag.page.subscribe((e: PageObject) => {
      if (
        this.curPageObj.pageSize !== e.pageSize &&
        this.curPageObj.pageIndex !== 0
      ) {
        e.pageIndex = 0;
        this.rangeStart = 0;
        this.rangeEnd = this.showTotalPage - 1;
      }
      this.curPageObj = e;

      this.initPageRange();
    });
  }

  private buildPageNumbers(): void {
    const paginatorContainer = this.vr.element.nativeElement.querySelector(
      'div.mat-mdc-paginator-container'
    );
    const pageSize = this.vr.element.nativeElement.querySelector(
      'div.mat-mdc-paginator-page-size'
    );
    const actionContainer = this.vr.element.nativeElement.querySelector(
      'div.mat-mdc-paginator-range-actions'
    );
    const nextPageNode = this.vr.element.nativeElement.querySelector(
      'button.mat-mdc-paginator-navigation-next'
    );
    const prevButtonCount = this.buttons.length;
    const pageLabel = this.vr.element.nativeElement.querySelector(
      'div.mat-mdc-paginator-range-label'
    );
    this.ren.removeChild(actionContainer, pageLabel);
    this.ren.appendChild(paginatorContainer, pageLabel);
    this.ren.addClass(paginatorContainer, 'justify-content-between');
    this.ren.addClass(paginatorContainer, 'flex-row-reverse');
    this.ren.addClass(paginatorContainer, 'px-0');
    this.ren.addClass(pageSize, 'me-0');
    this.ren.addClass(pageLabel, 'm-0');
    // remove buttons before creating new ones
    if (this.buttons.length > 0) {
      this.buttons.forEach((button) => {
        this.ren.removeChild(actionContainer, button);
      });
      // Empty state array
      this.buttons.length = 0;
    }

    // initialize next page and last page buttons
    if (this.buttons.length === 0) {
      const nodeArray = this.vr.element.nativeElement?.childNodes[0]
        ?.childNodes[0]?.childNodes[2]?.childNodes;
      if (nodeArray) {
        setTimeout(() => {
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < nodeArray.length; i++) {
            if (nodeArray[i].nodeName === 'BUTTON') {
              if (
                nodeArray[i].innerHTML.length > 100 &&
                nodeArray[i].disabled
              ) {
                this.ren.addClass(nodeArray[i], 'prevnextButton');
              } else if (
                nodeArray[i].innerHTML.length > 100 &&
                !nodeArray[i].disabled
              ) {
                this.ren.addClass(nodeArray[i], 'prevnextButton');
              } else if (nodeArray[i].disabled) {
                this.ren.addClass(nodeArray[i], 'pageNo');
              }
            }
          }
        });
      }
    }

    for (let i = 0; i < this.numOfPages; i++) {
      let val = 0;
      if (i >= this.rangeStart && i <= this.rangeEnd) {
        this.ren.insertBefore(
          actionContainer,
          this.createButton(i, this.matPag.pageIndex),
          nextPageNode
        );
        val++;
      }
    }

    if (this.numOfPages === 0) {
      this.ren.insertBefore(
        actionContainer,
        this.createButton(0, this.matPag.pageIndex),
        nextPageNode
      );
    }
  }

  private createButton(i: any, pageIndex: number): any {
    const linkBtn: MatButton = this.ren.createElement('button');
    this.ren.addClass(linkBtn, 'mat-flat-button');

    this.ren.addClass(linkBtn, 'btn');

    const pagingTxt = isNaN(i) ? this.pageGapTxt : +(i + 1);
    const text = this.ren.createText(pagingTxt + '');

    this.ren.addClass(linkBtn, 'mat-custom-page');
    switch (i) {
      case pageIndex:
        this.ren.setAttribute(linkBtn, 'disabled', 'disabled');
        break;
      case pageIndex - 1:
        this.ren.setStyle(linkBtn, 'display', 'none');
        break;
      case pageIndex + 1:
        this.ren.setStyle(linkBtn, 'display', 'none');
        break;
      case this.pageGapTxt:
        let newIndex = this.curPageObj.pageIndex + this.showTotalPage;

        if (newIndex >= this.numOfPages) {
          newIndex = this.lastPageIndex;
        }

        if (pageIndex !== this.lastPageIndex) {
          this.ren.listen(linkBtn, 'click', () => {
            this.switchPage(newIndex);
          });
        }

        if (pageIndex === this.lastPageIndex) {
          this.ren.setAttribute(linkBtn, 'disabled', 'disabled');
        }
        break;
      default:
        this.ren.listen(linkBtn, 'click', () => {
          this.switchPage(i);
        });
        break;
    }

    this.ren.appendChild(linkBtn, text);
    // Add button to private array for state
    this.buttons.push(linkBtn);
    return linkBtn;
  }
  // calculates the button range based on class input parameters and based on current page index value.
  // Used to render new buttons after event.
  private initPageRange(): void {
    const middleIndex = (this.rangeStart + this.rangeEnd) / 2;
    this.rangeStart = this.calcRangeStart(middleIndex);
    this.rangeEnd = this.calcRangeEnd(middleIndex);

    this.buildPageNumbers();
  }

  // Helper function To calculate start of button range
  private calcRangeStart(middleIndex: number): number {
    const { pageIndex, previousPageIndex } = this.curPageObj;
    switch (true) {
      case pageIndex === 0 && this.rangeStart !== 0:
        return 0;
      case pageIndex > this.rangeEnd:
        return pageIndex + this.inc > this.lastPageIndex
          ? this.lastPageIndex - this.inc * 2
          : pageIndex - this.inc;
      case pageIndex > previousPageIndex &&
        pageIndex > middleIndex &&
        this.rangeEnd < this.lastPageIndex:
        return this.rangeStart + 1;
      case pageIndex < previousPageIndex &&
        pageIndex < middleIndex &&
        this.rangeStart > 0:
        return this.rangeStart - 1;
      default:
        return this.rangeStart;
    }
  }
  // Helper function to calculate end of button range
  private calcRangeEnd(middleIndex: number): number {
    const { pageIndex, previousPageIndex } = this.curPageObj;
    switch (true) {
      case pageIndex === 0 && this.rangeEnd !== this.showTotalPages:
        return this.showTotalPage - 1;
      case pageIndex > this.rangeEnd:
        return pageIndex + this.inc > this.lastPageIndex
          ? this.lastPageIndex
          : pageIndex + 1;
      case pageIndex > previousPageIndex &&
        pageIndex > middleIndex &&
        this.rangeEnd < this.lastPageIndex:
        return this.rangeEnd + 1;
      case pageIndex < previousPageIndex &&
        pageIndex < middleIndex &&
        this.rangeStart >= 0 &&
        this.rangeEnd > this.showTotalPage - 1:
        return this.rangeEnd - 1;
      default:
        return this.rangeEnd;
    }
  }
  // Helper function to switch page on non first, last, next and previous buttons only.
  private switchPage(i: number): void {
    const previousPageIndex = this.matPag.pageIndex;
    this.matPag.pageIndex = i;
    // tslint:disable-next-line: no-string-literal
    this.matPag['_emitPageEvent'](previousPageIndex);
    this.initPageRange();
  }
  // Initialize default state after view init
  public ngAfterViewInit(): void {
    this.rangeStart = 0;
    this.rangeEnd = this.showTotalPage - 1;
    this.initPageRange();
  }
}
