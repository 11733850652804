import { Component, Inject, OnInit } from '@angular/core';
import {
  QuickTextDetails,
  QuickTextDetailsResponse,
} from '@app/model/interfaces/settings';
import { UserInfoService } from '../services/user-info.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToasterService } from '../services/toaster.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'clover-view-quick-texts',
  templateUrl: './view-quick-texts.component.html',
  styleUrls: ['./view-quick-texts.component.scss'],
})
export class ViewQuickTextsComponent implements OnInit {
  quickTextData!: QuickTextDetails[];
  quickTextCopy!: QuickTextDetails[];
  isLoading = true;
  selectedIndex!: number;
  quickText = '';
  constructor(
    private userInfo: UserInfoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sharedService: SharedService,
    private router: Router,
    public dialogRef: MatDialogRef<ViewQuickTextsComponent>,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.userInfo.getAllQuickTexts().subscribe(
      (res: QuickTextDetailsResponse) => {
        this.isLoading = false;
        this.quickTextData = res.data;
        this.quickTextCopy = res.data;
      },
      (err) => this.toasterService.error(err)
    );
  }
  getQuickText(item: QuickTextDetails, index: number) {
    this.quickText = item.description;
    this.selectedIndex = index;
  }
  onCancel = () => {
    this.dialogRef.close();
  };
  onInsert = () => {
    if (this.data && this.data.convertToValue) {
      this.sharedService.replaceQuickText(this.data.entityType, this.data.entityId, this.quickText).subscribe(
        (res) => {
          if (res.statusCode === 200) {
            this.dialogRef.close(res.data);
          }
        },
        (err) => this.toasterService.error(err)
      );
    } else {
      this.dialogRef.close(this.quickText);
    }
  };
  onChangeEvent = (event: Event) => {
    const searchText = (event.target as HTMLInputElement).value.toLowerCase();
    if (searchText.length > 0) {
      this.quickTextData = this.quickTextCopy.filter((item: QuickTextDetails) =>
        item.textName?.toLowerCase().includes(searchText.toLowerCase())
      );
      this.quickText = '';
      this.selectedIndex = 0;
    } else {
      this.quickTextData = [...this.quickTextCopy];
    }
  };
  routeToSettings = () => {
    this.dialogRef.close();
    this.router.navigate(['/settings/quickTexts']);
  };
}
