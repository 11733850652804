<h2 class="my-3 section-title2 text-center">
  {{ 'shared.microsoft.dialog.header' | translate }}
</h2>
<div class="dialog-container text-center">
  <p class="mb-5">
    {{ 'shared.microsoft.dialog.message' | translate }}
  </p>
  <div>
    <button class="btn outline-primary" (click)="setDeclined()">
      {{ 'shared.microsoft.button.decline' | translate }}
    </button>
    <button class="btn btn-primary ms-3" (click)="get_MicrosoftURL()">
      {{ 'shared.microsoft.button.accept' | translate }}
    </button>
  </div>
</div>
