<article class="p-0 mt-4 notificaion">
  <div class="listNotification">
    <div
      *ngFor="let notify of currentNotification; let i = index"
      class="positionRelative"
    >
      <div class="close-icon pointer" (click)="removeNotification(i)">
        <img src="assets/images/icons/icon_dialog_close.svg" />
      </div>
      <div
        class="row m-3"
        *ngIf="
          ['conCurrentNewSession', 'conCurrentExistingSession'].includes(
            notify.type
          )
        "
      >
        <div class="col-md-2 pt-3">
          <img src="assets/images/icons/concurrentSession_notif.svg" />
        </div>
        <div class="col-md-10 pt-2">
          <h6 class="notification-title fs-14">
            {{ 'conCurrentSessionHeading' | translate }}
          </h6>
          <p class="notification-list fs-13">{{ notify.message }}</p>
        </div>
      </div>

      <div
        class="row m-3 pointer"
        *ngIf="notify.type === 'newMoaList'"
        (click)="gotoList(notify.data.listId, i)"
      >
        <div class="col-md-2 ImageThumbnail">
          <img src="assets/images/icons/icon_task_1.svg" />
        </div>
        <div class="col-md-10 pt-2">
          <h6 class="notification-title fs-14">
            {{ 'moaListAssignment' | translate }}
          </h6>
          <p
            class="notification-list fs-13"
            [innerHTML]="notify.data.message"
          ></p>
        </div>
      </div>
    </div>
  </div>
</article>
