import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { pageSizeOptions } from '@app/appConstants';
import {
  SearchReq,
  SearchResultResponse,
  UserData,
  UserInfo,
} from '@app/model/interfaces/menu';
import {
  NotificationResponse,
  ReadNotificationRequest,
} from '@app/model/interfaces/notification';
import { BehaviorSubject, Subject, throwError, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { fixedEncodeURIComponent } from '../utils/functions';
import { LocalStorageService } from './local-storage.service';
import { CallService } from '../call/call.service';
import { UserInfoService } from './user-info.service';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  globalSearchTextChanged = new BehaviorSubject('');
  newMessageNotifications = new Subject();
  newNotificationAvailable = new Subject();
  newMoaNotificationAvailable = new Subject();
  checkCallStatus = new Subject();

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private callService: CallService,
    private userInfoService: UserInfoService
  ) {}

  private payload: SearchReq = {
    page: 0,
    recordsPerPage:
      Number(sessionStorage.getItem('previousIndex')) || pageSizeOptions[0],
    sortBy: 'email',
    sortOrder: 'ASC',
  };

  newMessageReceived = (webSocketData: any) => {
    this.newMessageNotifications.next(webSocketData);
  };

  newNotificationReceived = (webSocketData: any) => {
    this.newNotificationAvailable.next(webSocketData);
  };

  newMoaNotificationReceived = (webSocketData: any) => {
    this.newMoaNotificationAvailable.next(webSocketData);
  };

  playSound = () => {
    const audio = new Audio('../../../../assets/sounds/chime.wav');
    let soundPreference = JSON.parse(
      this.localStorageService.getItem('playSound')
    );
    soundPreference = soundPreference === null ? true : soundPreference;
    if (soundPreference && !this.callService.getCallActiveStatus()) {
      audio.play();
    }
  };

  onNotificationReceived = () => this.newNotificationAvailable.asObservable();
  onMoaNotificationReceived = () =>
    this.newMoaNotificationAvailable.asObservable();
  onMessageReceived = () => this.newMessageNotifications.asObservable();

  setSearchPayloadPage(page: number): void {
    this.payload.page = page;
  }
  setSearchPayloadRecordsPerPage(recordsPerPage: number): void {
    this.payload.recordsPerPage = recordsPerPage;
  }
  setSearchPayloadSortBy(sortBy: string): void {
    this.payload.sortBy = sortBy;
  }
  setSearchPayloadSortOrder(sortOrder: string): void {
    this.payload.sortOrder = sortOrder;
  }
  getSearchPayload(): SearchReq {
    return this.payload;
  }

  getUserData(): Observable<UserData> {
    return this.http.post(`/v1/userdata`, {}).pipe(
      catchError(this.handleError),
      tap((response: UserData) => {})
    );
  }

  getUserAccess(): Observable<UserInfo> {
    return this.http.get(`${environment.referralServices}/v1/useraccess/`).pipe(
      catchError(this.handleError),
      tap((response: UserInfo) => {})
    );
  }
  getSearchReferralResults(
    bankId: number,
    searchTerm: string,
    payload: SearchReq
  ): Observable<SearchResultResponse> {
    searchTerm = fixedEncodeURIComponent(searchTerm);
    return this.http
      .get(
        `${environment.referralServices}/v1/bank/${bankId}/referrals/search/${searchTerm}?`,
        { params: payload as any }
      )
      .pipe(
        catchError(this.handleError),
        tap((response: SearchResultResponse) => {})
      );
  }

  getSearchResults(
    moduleName: string,
    payload: SearchReq,
    version: string,
    bankId: number,
    refineSearch = false
  ): Observable<SearchResultResponse> {
    let serviceURL = '';
    if (moduleName == 'moalist' || moduleName == 'prospect') {
      serviceURL = `${environment.prospectServices}/v1/bank/${bankId}/${
        refineSearch ? 'refinesearch' : 'globalsearch'
      }/${moduleName}`;
      payload.userId = this.userInfoService.getUserId()
    } else if (moduleName == 'account') {
      serviceURL = `${environment.accountServices}/v1/globalsearch/account`;
    } else {
      serviceURL = `${environment.salesRegimeServices}/${version}/globalsearch/${moduleName}`;
    }
    return this.http.post(`${serviceURL}`, payload).pipe(
      catchError(this.handleError),
      tap((response: SearchResultResponse) => {})
    );
  }
  getUserNotification(userId: string): Observable<NotificationResponse> {
    return this.http
      .get(`${environment.referralServices}/v1/users/notification/${userId}/`)
      .pipe(
        catchError(this.handleError),
        tap((response: NotificationResponse) => {})
      );
  }
  getLatestUserNotificationCount(
    userId: string,
    maxNotificationId: number
  ): Observable<NotificationResponse> {
    return this.http
      .get(
        `${environment.referralServices}/v1/users/notification/${userId}/?maxNotificationId=${maxNotificationId}`
      )
      .pipe(
        catchError(this.handleError),
        tap((response: NotificationResponse) => {})
      );
  }
  clearAllNotification(
    payload: ReadNotificationRequest
  ): Observable<NotificationResponse> {
    return this.http
      .post(
        `${environment.referralServices}/v1/users/notification/clear/`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap((response: NotificationResponse) => {})
      );
  }

  readNotification(
    payload: ReadNotificationRequest
  ): Observable<NotificationResponse> {
    return this.http
      .post(
        `${environment.referralServices}/v1/users/notification/read/`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap((response: NotificationResponse) => {})
      );
  }
  checkNotificationStatus = (id: number) => {
    return this.http
      .get(`${environment.salesRegimeServices}/v1/entity/latest/lead/${id}`)
      .pipe(
        catchError(this.handleError),
        tap((response: NotificationResponse) => {})
      );
  };

  getReportData(reportId: string): Observable<NotificationResponse> {
    return this.http
      .get(`${environment.referralServices}/v1/report/${reportId}`)
      .pipe(
        catchError(this.handleError),
        tap((response: NotificationResponse) => {})
      );
  }
  handleError(error: HttpErrorResponse): Observable<any> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
