import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { websocketURL } from './appConstants';

@Injectable({
  providedIn: 'root',
})
export class WebsocketsService {
  attemptToConnectEvent = new BehaviorSubject('');
  socket!: WebSocket;
  domainType!: string;
  userId!: string;

  constructor() {}

  getSocketOn = (userId: string, domainType: string) => {
    this.userId = userId;
    this.domainType = domainType;
    const domainUrl = `${
      domainType === 'prod' ? websocketURL.prod : websocketURL.dev
    }?name=${userId}`;

    this.socket = new WebSocket(domainUrl);
    this.socket.onopen = (ev: any) => {};
    this.socket.onclose = (ev: CloseEvent) => {
      this.socket.CLOSED;
      this.attemptToConnectEvent.next('');
    };
  };
}
