<div class="feature-wrapper" [class.isSmartDevice]="isSmartDevice">
  <div class="left-menu" [class.expandMenu]="expandMenu" *ngIf="showOnDesktop">
    <clover-left-menu
      (expandMenuEvent)="updateExpandMenuStatus($event)"
    ></clover-left-menu>
  </div>
  <div
    class="feature-content"
    [ngClass]="{ 'extend px-3 py-0': !showOnDesktop }"
  >
    <router-outlet></router-outlet>
  </div>
</div>
