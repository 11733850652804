import { NgModule } from '@angular/core';
import { FocusInvalidInputFieldDirective } from './focus-invalid-input-field';
import { DndDirective } from './dnd.directive';
import { PhoneMaskDirective } from './phone-mask.directive';
import { AlphabetNumericOnlyDirective } from './alphaNumeric.directive';
import { StylePaginatorDirective } from './style-paginator.directive';
import { OutsideClickDirective } from './outside-click.directive';
import { NoAutocompleteDirective } from './autocomplete.directive';
@NgModule({
  declarations: [
    FocusInvalidInputFieldDirective,
    DndDirective,
    PhoneMaskDirective,
    AlphabetNumericOnlyDirective,
    StylePaginatorDirective,
    OutsideClickDirective,
    NoAutocompleteDirective
  ],
  exports: [
    FocusInvalidInputFieldDirective,
    DndDirective,
    PhoneMaskDirective,
    AlphabetNumericOnlyDirective,
    StylePaginatorDirective,
    OutsideClickDirective,
    NoAutocompleteDirective
  ],
})
export class DirectiveModule {}
