import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserInfoService } from './user-info.service';

@Injectable()
export class CloverHttpInterceptor implements HttpInterceptor {

  constructor(private userInfoService: UserInfoService) { }
  intercept(
    httpRequest: HttpRequest<string>,
    next: HttpHandler
  ): Observable<HttpEvent<Event>> {
    // add custom header
    return next.handle(httpRequest).pipe(
      map((event: HttpEvent<Event>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (error.error && error.error.redirectURL) {
            window.location.href = error.error.redirectURL;
          } else {
            const userAuthInfo = this.userInfoService.getUserAuthInfo();
            this.userInfoService.logout().subscribe(
              () => (window.location.href = userAuthInfo.redirectUrl),
              () => (window.location.href = userAuthInfo.redirectUrl)
            );
          }
        }
        return throwError(error);
      }));
  }
}
