import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSelect } from '@angular/material/select';
import { ColumnFilterValue } from '@app/model/interfaces/common';
import { LanguageService } from '@app/shared/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'clover-mat-column-filter',
  templateUrl: './mat-column-filter.component.html',
  styleUrls: ['./mat-column-filter.component.scss'],
})
export class MatColumnFilterComponent implements OnInit, AfterViewInit {
  @Input() field!: string;
  @Input() fieldType!: string;
  @Input() placeholderInput!: string;
  @Input() dropDown = false;
  @Input() multiple = false;
  @Input() datefilter = false;
  @Input() rangefilter = false;
  @Input() previousVal!: any | '';

  @Output() clearEvent = new EventEmitter();
  @Output() applyEvent = new EventEmitter();
  @Output() hideEvent = new EventEmitter();
  @Input() filteredOptions!: any[];

  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  inputAutoComplete!: MatAutocompleteTrigger;
  myControl = new FormControl();
  gte = new FormControl();
  lte = new FormControl();
  selectedUsers: string[] = new Array<string>();
  @ViewChild(MatSelect)
  mySelector!: MatSelect;

  @ViewChild('picker') picker!: MatDatepicker<Date>;
  customDate = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  @Output()
  appOutsideClick = new EventEmitter();
  matSelectIsOpended = false;
  isValidNumber = true;
  isGreaterLessError = false;

  regexStr = /^\d*\.?\d*$/g;
  constructor(
    private cdRef: ChangeDetectorRef,
    private translate: TranslateService,
    private language: LanguageService
  ) {}

  ngOnInit(): void {
    this.language.languageChanged.subscribe({
      next: (res: string) => {
        this.translate.setDefaultLang(res);
      },
      error: (error) => {},
      complete: () => {},
    });
  }
  ngAfterViewInit(): void {
    if (this.filteredOptions?.length > 0 && !this.previousVal) {
      this.myControl.setValue(this.filteredOptions);
      this.mySelector?.open();
      this.cdRef.detectChanges();
    }
    if (this.previousVal) {
      if (!this.datefilter && !this.rangefilter) {
        const arr: any[] = [];
        this.previousVal.split(';').forEach((val: any) => {
          arr.push(val);
        });
        this.myControl.setValue(arr);
        this.mySelector?.open();
      } else if (this.rangefilter) {
        if (this.previousVal) {
          this.previousVal = this.previousVal.split(';');
          if (this.previousVal.length === 2) {
            this.lte.patchValue(this.previousVal[0].split('=')[1]);
            this.gte.patchValue(this.previousVal[1].split('=')[1]);
          } else {
            if (this.previousVal[0].split('=')[0] === 'greater') {
              this.lte.patchValue(this.previousVal[0].split('=')[1]);
            } else {
              this.gte.patchValue(this.previousVal[0].split('=')[1]);
            }
          }
        }
      } else {
        this.previousVal = this.previousVal.split('undefined').join('');
        this.previousVal = this.previousVal.split('-').join('');
        const date = this.previousVal.split(',');
        if (date[0] && date[1]) {
          this.customDate.patchValue({
            start: moment(date[0], 'YYYYMMDD'),
            end: moment(date[1], 'YYYYMMDD'),
          });
        }
      }
      this.cdRef.detectChanges();
    }
    if (this.datefilter) {
      setTimeout(() => {
        this.picker?.open();
      }, 100);
    }
    this.mySelector?.openedChange.subscribe((isOpen) => {
      if (!isOpen) {
        this.hideEvent.emit('cancel');
      }
    });
    this.picker?.closedStream.subscribe((close) => {
      this.hideEvent.emit('cancel');
    });
  }
  onKeyDown(event: KeyboardEvent, input: string = ''): boolean {
    if (
      this.fieldType === 'percent' ||
      this.fieldType === 'number' ||
      this.rangefilter
    ) {
      this.isValidNumber = true;
      let tempVal = '';
      if (input === 'greater') {
        tempVal = this.gte.value ? this.gte.value + event.key : event.key;
      } else if (input === 'lower') {
        tempVal = this.lte.value ? this.lte.value + event.key : event.key;
      } else {
        tempVal = this.myControl.value
          ? this.myControl.value + event.key
          : event.key;
      }

      if (!['Backspace', 'Tab'].includes(event.key)) {
        this.isValidNumber = new RegExp(this.regexStr).test(tempVal);
      }
      return this.isValidNumber;
    }
    return true;
  }
  onApply(evt: { stopPropagation: () => void }): void {
    evt.stopPropagation();
    if (!this.datefilter && !this.rangefilter) {
      if (this.dropDown) {
        this.mySelector?.close();
        if (typeof this.myControl.value[0] === 'string') {
          const emitObj: ColumnFilterValue = {
            data:
              this.field +
              ':' +
              this.myControl.value.toString().split(',').join(';'),
            field: 'input',
          };
          this.applyEvent.emit(emitObj);
        }
      } else {
        if (this.myControl.value) {
          const emitObj: ColumnFilterValue = {
            data: `${this.field}:${this.myControl.value}`,
            field: 'input',
          };
          this.applyEvent.emit(emitObj);
        }
      }
    } else if (this.rangefilter) {
      if (this.gte.value && this.lte.value) {
        const gteValue = Number(this.gte.value);
        const lteValue = Number(this.lte.value);
        if (lteValue > gteValue) {
          this.isGreaterLessError = true;
        } else {
          this.isGreaterLessError = false;
          const emitObj: ColumnFilterValue = {
            data: `${this.field}:greater=${lteValue};lower=${gteValue}`,
            field: 'range',
          };
          this.applyEvent.emit(emitObj);
        }
      } else if (this.gte.value && !this.lte.value) {
        const gteValue = Number(this.gte.value);
        const emitObj: ColumnFilterValue = {
          data: `${this.field}:lower=${gteValue}`,
          field: 'range',
        };
        this.applyEvent.emit(emitObj);
      } else if (!this.gte.value && this.lte.value) {
        const lteValue = Number(this.lte.value);
        const emitObj: ColumnFilterValue = {
          data: `${this.field}:greater=${lteValue}`,
          field: 'range',
        };
        this.applyEvent.emit(emitObj);
      }
    } else {
      this.mySelector?.close();
      if (!this.customDate.value.start) {
        this.customDate.patchValue({
          start: moment(new Date()),
        });
      }
      if (!this.customDate.value.end) {
        this.customDate.patchValue({
          end: this.customDate.value.start,
        });
      }
      const dateVal =
        'fromDate:' +
        this.customDate.value.start.format('YYYYMMDD') +
        ',' +
        'toDate:' +
        this.customDate.value.end.format('YYYYMMDD');
      const emitObj: ColumnFilterValue = {
        data: dateVal,
        field: 'date',
      };
      this.applyEvent.emit(emitObj);
    }
  }
  onCancel(evt: { stopPropagation: () => void }): void {
    evt.stopPropagation();
    if (!this.datefilter) {
      this.myControl.setValue('');
    } else {
      this.customDate.patchValue({
        start: '',
        end: '',
      });
    }
    this.clearEvent.emit('cancel');
  }

  toggleSelection(user: any): void {
    user.selected = !user.selected;
    if (user.selected) {
      this.selectedUsers.push(user.firstname);
    } else {
      this.selectedUsers.splice(1);
    }

    this.myControl.setValue(this.selectedUsers);
  }
  onslectAll(): void {
    this.filteredOptions.forEach((row) => {
      row.selected = true;
    });
  }
}
