import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[cloverOutsideClick]',
})
export class OutsideClickDirective {
  @Output()
  appOutsideClick = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: any): void {
    if (
      (typeof targetElement.className === 'string' ||
        targetElement.className instanceof String) &&
      targetElement.className.indexOf('filterIcon') === -1
    ) {
      if(this.elementRef.nativeElement.getAttribute('ng-reflect-datefilter')) {
        const clickedInside = (targetElement.className.indexOf('cdk-overlay-backdrop') != -1
          ? false
          : true);
        this.appOutsideClick.emit(!clickedInside);
      } else {
        const clickedInside =
        this.elementRef.nativeElement.contains(targetElement)
        this.appOutsideClick.emit(!clickedInside);
      }
    }
  }
}
