import { Component, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { ToasterMessage } from '@app/model/interfaces/toaster.interface';

@Component({
  selector: 'clover-toaster',
  template: `<div class="d-flex justify-content-between">
    <div class="mt-1" [innerHTML]="data.msg"></div>
    <div>
      <button class="btn-icon ms-2 me-0 toasterClose" (click)="closeToaster()">
        <img src="assets/images/icons/icon_close_white.svg" />
      </button>
    </div>
  </div>`,
})
export class ToasterComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: ToasterMessage,
    private matSnackRef: MatSnackBarRef<ToasterComponent>
  ) {}
  closeToaster = () => {
    this.matSnackRef.dismiss();
  };
}
