<div class="dialog-container p-2">
  <div class="dialog-header">
    <div class="dialog-header-btn">
      <button class="btn btn-icon" (click)="onCancel()">
        <img class="x-large" src="assets/images/icons/icon_times.svg" />
      </button>
    </div>
  </div>
  <div class="dialog-content p-2">
    <div class="modal-body" id="">
      <div class="row">
        <div class="col-md-6">
          <h2 class="section-title">
            {{ 'lead.dialog.businessInformation' | translate }}
          </h2>
          <div class="row">
            <div class="col-md-12">
              <div class="ac_grp">
                <div class="ac_desc">{{ leadDetails.ACCOUNTCOMPANY }}</div>
                <div
                  class="ca_value my-3"
                  *ngIf="latNlong"
                >
                  <google-map
                    height="150px"
                    width="100%"
                    [center]="latNlong"
                    [zoom]="zoom"
                  >
                    <map-marker [position]="latNlong"></map-marker
                  ></google-map>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="ac_grp">
                <div class="camp_grp">
                  <div class="cm_cell img-container">
                    <img
                      src="assets/images/icons/icon_industry.svg"
                      alt="Money Payments"
                      title="Money Payments"
                      class="mCS_img_loaded"
                    />
                  </div>
                  <div class="cm_cell">
                    <div class="ca_desc">
                      {{ 'shared.common.industry' | translate }}
                    </div>
                    <div class="ca_value">{{ leadDetails.INDUSTRY }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <h2 class="section-title">
            {{ 'lead.dialog.contactInformation' | translate }}
          </h2>
          <div class="row">
            <div class="col-md-6">
              <div class="ac_grp">
                <div class="ac_desc">
                  {{ 'referralTableColumnName.firstName' | translate }}
                </div>
                <div class="ca_value">{{ leadDetails.FIRST_NAME }}</div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="ac_grp">
                <div class="ac_desc">
                  {{ 'referralTableColumnName.lastName' | translate }}
                </div>
                <div class="ca_value">{{ leadDetails.LAST_NAME }}</div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="ac_grp">
                <div class="ac_desc">
                  {{ 'referralTableColumnName.phone' | translate }}
                </div>
                <div class="ca_value">{{ leadDetails.PHONE }}</div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="ac_grp">
                <div class="ac_desc">
                  {{ 'shared.common.email' | translate }}
                </div>
                <div class="ca_value">{{ leadDetails.EMAIL }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <h2 class="section-title2">
        {{ 'lead.dialog.activeCampaign' | translate }}
      </h2>
      <div class="row">
        <div class="col-md-6">
          <div class="ac_grp">
            <div class="ac_desc">{{ 'referral.campaignName' | translate }}</div>
            <div class="ca_value">{{ leadDetails.CampaignName }}</div>
          </div>
          <div class="ac_grp">
            <div class="ac_desc">{{ 'lead.dialog.expiry' | translate }}</div>
            <div class="ca_value">
              {{ moment(leadDetails.CampaignExpiry).format('MMMM DD, YYYY') }}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="ca_value">
            {{ 'lead.dialog.campaignDescription' | translate }}
          </div>
          <div class="ac_desc mtop16">
            {{ leadDetails.CampaignDescription }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
