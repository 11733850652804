import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { LanguageService } from '@app/shared/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/services/toaster.service';
import { MyAccountsService } from '../my-accounts.service';
import { Router } from '@angular/router';
import {
  Notification
} from '@app/model/interfaces/notification';
import {
  DisplayName,
  RiskAlertsBatchReportResponse,
  RiskAlertsBatchReportDetails
} from '@app/model/interfaces/riskalerts';
import { 
  riskAlertsBatchReportTableGrid,
  RiskAlertsStaticContent,
  riskAlertsNotificationTypes,
  riskAlertsHeadingContent,
  riskAlertsBatchHeadingContent
 } from '../riskalertsConstant';

@Component({
  selector: 'clover-risk-alerts-batch-view-details',
  templateUrl: './risk-alerts-batch-view-details.component.html',
  styleUrls: ['./risk-alerts-batch-view-details.component.scss']
})
export class RiskAlertsBatchViewDetailsComponent implements OnInit {
  notificationData!: Notification;
  RiskAlertsBatchReportDetails!: RiskAlertsBatchReportDetails[];
  isRiskAlertsBatchReportLoading = false;
  dataSource = new MatTableDataSource<any>();
  displayColumnsDefined!: DisplayName[];
  displayedColumns!: string[];
  staticLocaleContent: {
    [key: string]: string;
  } = this.language.getLocaleString(RiskAlertsStaticContent);
  constructor(
    private translate: TranslateService,
    private language: LanguageService,
    private myAccountsService: MyAccountsService,
    private toasterService: ToasterService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RiskAlertsBatchViewDetailsComponent>
  ) {
     this.notificationData = this.data?.notificationData;    
  }

  ngOnInit(): void {
    this.language.languageChanged.subscribe({
      next: (res: string) => {
        this.translate.setDefaultLang(res);
      },
      error: (error) => {},
      complete: () => {},
    });
    this.getTableColumns();
    this.getRiskAlertCommunicationLogs();
  }

  getTableColumns = () => {
    this.displayColumnsDefined = [...riskAlertsBatchReportTableGrid];
    this.displayedColumns = this.displayColumnsDefined.map(
      (col: { dataFieldName: string }) => col.dataFieldName
    );
    const notificationType = this.notificationData.notificationBodyParsed?.notificationType;
    if (notificationType && notificationType === riskAlertsNotificationTypes.RISK_ALERTS_ISSUED) {
      this.displayColumnsDefined.push({
        dataFieldName: 'riskType',
        displayName: 'Risk Type',
        isSortable: false,
        hasFilter: false,
        fieldType: 'text',
        multiLanguageKey: 'riskalertsTableColumnName.riskType',
      });
      this.displayedColumns.push('riskType');
    } else if (notificationType && notificationType === riskAlertsNotificationTypes.RISK_ALERTS_UPDATED) {
      this.displayColumnsDefined.push({
        dataFieldName: 'status',
        displayName: 'Status',
        isSortable: false,
        hasFilter: false,
        fieldType: 'text',
        multiLanguageKey: 'shared.status',
      });
      this.displayedColumns.push('status');
    } else if (notificationType && notificationType === riskAlertsNotificationTypes.RISK_ALERTS_COMMUNICATION_RECEIVED) {
      this.displayColumnsDefined.push({
        dataFieldName: 'communicationType',
        displayName: 'Communication Type',
        isSortable: false,
        hasFilter: false,
        fieldType: 'text',
        multiLanguageKey: 'riskAlerts.commLogsTableColumns.communicationType',
      });
      this.displayedColumns.push('communicationType');
    }
  };

  getRiskAlertCommunicationLogs = () => {
    this.isRiskAlertsBatchReportLoading = true;
    const reqPayload = {};
    const entityId = this.notificationData.notificationBodyParsed?.entityId;
    this.myAccountsService
      .getRiskAlertsBatchReport(entityId, reqPayload)
      .subscribe({
        next: (res: RiskAlertsBatchReportResponse) => {
          if (res.statusCode === 200) {
            this.isRiskAlertsBatchReportLoading = false;
            this.RiskAlertsBatchReportDetails = res.data?.result;            
            this.dataSource = new MatTableDataSource(this.RiskAlertsBatchReportDetails || []);            
          } else {
            this.toasterService.error(
              res.message || this.staticLocaleContent.somethingApiError
            );
          }
        },
        error: () => {
          this.isRiskAlertsBatchReportLoading = false;
          this.toasterService.error(this.staticLocaleContent.somethingApiError);
        }
      });
  }

  getRiskAlertsTitleText = (notificationData: Notification) => {
    let riskAlertsHeadingText: string = '';
    let riskAlertsHeadingTextContent:any = [];
    if (notificationData.notificationGroupName === 'RiskAlerts') {
      riskAlertsHeadingTextContent = [...riskAlertsHeadingContent];
    } else if (notificationData.notificationGroupName === 'RiskAlertsBatch') {
      riskAlertsHeadingTextContent = [...riskAlertsBatchHeadingContent];
    }
    if (notificationData && notificationData.notificationBodyParsed?.notificationType) {
      let notificationType = notificationData.notificationBodyParsed?.notificationType;
      let riskAlertsHeadingTextItem = riskAlertsHeadingTextContent.find((item: any) => item.notificationType === notificationType);
      if (riskAlertsHeadingTextItem && riskAlertsHeadingTextItem.headingText) {
        riskAlertsHeadingText = riskAlertsHeadingTextItem.headingText;
      }
    }
    return riskAlertsHeadingText;
  };

  navigateToRiskAlerts(notificationData: Notification, accountId: number) {
    this.onCancel();
    let notificationType = notificationData.notificationBodyParsed?.notificationType;
    if (notificationType === riskAlertsNotificationTypes.RISK_ALERTS_ISSUED 
      || notificationType === riskAlertsNotificationTypes.RISK_ALERTS_UPDATED) {
        this.router.navigate([`/myaccounts/details/${accountId}/riskalerts`]);
    } else if (notificationType === riskAlertsNotificationTypes.RISK_ALERTS_COMMUNICATION_RECEIVED) {
      this.router.navigate([`/myaccounts/details/${accountId}/riskalerts/communicationlogs`]);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

}
