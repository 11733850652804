import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AgendaParams,
  AgendaTaskDetailResponse,
  AgendaTaskListResponse,
  AgendaTypesResponse,
  CampaignsPopupResponse,
  NewAgendaTaskCountResponse,
  NewAgendaTaskListResponse,
  Prospecting,
  TaskType,
} from '@app/model/interfaces/agenda';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AgendaService {
  private taskData!: Prospecting;
  private taskTypes!: TaskType[];
  private params!: AgendaParams;
  constructor(private http: HttpClient) {}

  taskIdChanged = new BehaviorSubject('');
  refreshTaskList = new BehaviorSubject('');
  updateAgendaTaskCount = new BehaviorSubject('');

  getTaskData = () => this.taskData;

  setTaskData = (data: Prospecting) => {
    this.taskData = data;
  };
  getParams = () => {
    return this.params;
  };
  setParams = (params: AgendaParams) => {
    this.params = params;
  };

  getTaskTypeData = () => {
    return this.taskTypes;
  };
  setTaskTypeData = (data: TaskType[]) => {
    this.taskTypes = data;
  };

  getAgendaTypes = (): Observable<AgendaTypesResponse> => {
    const url = `${environment.salesRegimeServices}/v1/agenda/task/types`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: AgendaTypesResponse) => {})
    );
  };

  getAgendaTasksList = (): Observable<AgendaTaskListResponse> => {
    const url = `${environment.salesRegimeServices}/v2/agenda/task`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: AgendaTaskListResponse) => {})
    );
  };
  getNewAgendaTasksCount = (
    filter: string
  ): Observable<NewAgendaTaskCountResponse> => {
    const url = `${environment.salesRegimeServices}/v3/agenda/task/counts?filterString=${filter}`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: NewAgendaTaskCountResponse) => {})
    );
  };
  getNewAgendaTasksList = (
    filter: string
  ): Observable<NewAgendaTaskListResponse> => {
    const url = `${environment.salesRegimeServices}/v3/agenda/tasks?filterString=${filter}`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: NewAgendaTaskListResponse) => {})
    );
  };
  getTaskDetailsById = (
    entityType: string,
    entityID: number,
    id: string
  ): Observable<AgendaTaskDetailResponse> => {
    const url = `${environment.salesRegimeServices}/v1/${entityType}/${entityID}/agenda/task/${id}`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: AgendaTaskDetailResponse) => {})
    );
  };
  getOpportunityTaskDetailsById = (
    id: number
  ): Observable<AgendaTaskDetailResponse> => {
    const url = `${environment.salesRegimeServices}/v1/agenda/opportunity/task/${id}`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: AgendaTaskDetailResponse) => {})
    );
  };
  saveTaskDetailsById = (
    entityType: string,
    id: string,
    entityId: number,
    payLoad: { action: string; data?: string }
  ): Observable<AgendaTaskDetailResponse> => {
    const url = `${environment.salesRegimeServices}/v1/${entityType}/${entityId}/agenda/task/${id}`;
    return this.http.post(url, payLoad).pipe(
      catchError(this.handleError),
      tap((response: AgendaTaskDetailResponse) => {})
    );
  };
  getCampaignData = (
    campaignId: number
  ): Observable<CampaignsPopupResponse> => {
    const url = `${environment.salesRegimeServices}/v1/campaign/${campaignId}`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: CampaignsPopupResponse) => {})
    );
  };

  handleError(error: HttpErrorResponse): Observable<any> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
