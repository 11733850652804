import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LeadDetailsCompo } from '@app/model/interfaces/referral';
import { GoogleMapService } from '@app/shared/google-map.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Component({
  selector: 'clover-lead-details-dialog',
  templateUrl: './lead-details-dialog.component.html',
  styleUrls: ['./lead-details-dialog.component.scss'],
})
export class LeadDetailsDialogComponent implements OnInit {
  leadDetails!: LeadDetailsCompo;
  latNlong = { lat: 0, lng: 0 };
  zoom = 15;
  showMap = false;
  moment = moment;
  constructor(
    public dialogRef: MatDialogRef<LeadDetailsDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: LeadDetailsCompo,
    private googleMapService: GoogleMapService
  ) {
    if (data.leadDetails) {
      this.leadDetails = data.leadDetails;
      this.leadDetails.Latitude = this.leadDetails.Latitude
        ? this.leadDetails.Latitude
        : 0;
      this.leadDetails.Longitude = this.leadDetails.Longitude
        ? this.leadDetails.Longitude
        : 0;
    }
  }

  ngOnInit(): void {
    this.getLatNLong(this.leadDetails);
  }

  getLatNLong = (address: LeadDetailsCompo) => {
    const longAddress = `${address.STREET} ${address.ZIPPOSTAL_CODE}`;
    if (longAddress.trim().length) {
      this.googleMapService.getLatNLong(longAddress).subscribe((res) => {
        this.latNlong = { ...res.results[0].geometry.location };
        this.showMap = true;
      });
    }
  };

  onCancel(): void {
    this.dialogRef.close();
  }
}
