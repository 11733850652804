import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  DeclinedURLResponse,
  MicrosoftURLResponse,
} from '@app/model/interfaces/menu';
import { LanguageService } from '@app/shared/services/language.service';
import { ToasterService } from '@app/shared/services/toaster.service';
import { UserInfoService } from '@app/shared/services/user-info.service';
import { microsoftAuthStaticContent } from '@app/shared/sharedConstants';

@Component({
  selector: 'clover-met-merchant',
  templateUrl: './microsoft-auth.component.html',
  styleUrls: ['./microsoft-auth.component.scss'],
})
export class MicrosoftAuthComponent {
  staticContent: { [key: string]: string } = this.language.getLocaleString(
    microsoftAuthStaticContent
  );
  constructor(
    private userInfoService: UserInfoService,
    private toasterService: ToasterService,
    private language: LanguageService,
    public dialogRef: MatDialogRef<MicrosoftAuthComponent>
  ) {}

  get_MicrosoftURL = () => {
    this.userInfoService.getMicrosoftURL().subscribe(
      (res: MicrosoftURLResponse) => {
        if (res.statusCode === 200) {
          const url = res.data.msalUrl;
          window.open(url, 'new');
          this.dialogRef.close();
        }
      },
      (err: any) => this.toasterService.error(err)
    );
  };

  setDeclined = () => {
    this.dialogRef.close();
    this.userInfoService.setDeclined().subscribe(
      (res: DeclinedURLResponse) => {
        if (res.statusCode === 200) {
          this.toasterService.success(this.staticContent.declinedMessage);
          this.userInfoService.setMicrosoftDeclined(true);
        } else {
          this.toasterService.error(this.staticContent.somthingWentWrong);
        }
      },
      (err: any) => this.toasterService.error(err)
    );
  };
}
