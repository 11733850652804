import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BookingEvent,
  BookingEventResponse,
  cancelMeetingInviteResponse,
  CheckUserActive,
  DeclinedURLResponse,
  FeatureModuleVersion,
  LanguageResponse,
  LogoutResponse,
  MenuItem,
  MicrosoftURLResponse,
  PreferenceJson,
  PreferenceJsonResponse,
  UserData,
  UserInfo,
} from '@app/model/interfaces/menu';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import {
  QuickTextDetailsResponse,
  QuickTextResponse,
} from '@app/model/interfaces/settings';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  meetingChanged = new BehaviorSubject('');
  private userInfo!: UserInfo;
  private microsoftURL!: string;
  private userAuthInfo!: UserData;
  private userPreferences!: PreferenceJson[];
  private showOnDesktop!: boolean;
  private selectedBankCountry!: string;
  private isFeaturesDataLoaded = false;
  private availableModuleFeatures: FeatureModuleVersion = {
    Campaign: 'V1',
    Agenda: 'V1',
    ShowBcPersonaFeatures: false,
    isRefineSearchAvailable: false,
  };

  constructor(private http: HttpClient) {}

  getUserInfo(): UserInfo {
    return this.userInfo;
  }

  setUserPreferences = (userPreferences: PreferenceJson[]) => {
    this.userPreferences = [...userPreferences];
  };

  getUserPreference = (type: string): string | null => {
    const userPreference: PreferenceJson =
      (this.userPreferences &&
        this.userPreferences.filter(
          (userPreference: PreferenceJson) =>
            userPreference.viewName.toLowerCase() === type
        )[0]) ||
      {};
    return userPreference.preferenceJson ? userPreference.preferenceJson : null;
  };

  getUserAuthInfo(): UserData {
    return this.userAuthInfo;
  }

  setMicrosoftURLPath = (url: string) => {
    this.microsoftURL = url;
  };
  getMicrosoftURLPath = () => this.microsoftURL;

  setMicrosoftDeclined = (isMsalAccessDeclined: boolean) => {
    this.userInfo.isMsalAccessDeclined = isMsalAccessDeclined;
  };
  getMicrosoftDeclined = () => this.userInfo.isMsalAccessDeclined;

  getUserId(): string {
    return this.userInfo.userId;
  }

  getNextMeetingEvent = (): Observable<BookingEventResponse> => {
    const url = `${environment.salesRegimeServices}/v1/user/msal/nextBookingEvent`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: BookingEventResponse) => {})
    );
  };

  getAppPreferences = (): Observable<PreferenceJsonResponse> => {
    const url = `${environment.salesRegimeServices}/v1/user/preferences/all`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: PreferenceJsonResponse) => {})
    );
  };
  updateAppPreference = (
    type: string,
    preferenceJson: string
  ): Observable<any> => {
    const url = `${environment.salesRegimeServices}/v1/user/preferences/${type}`;
    return this.http.post(url, { preferenceJson }).pipe(
      catchError(this.handleError),
      tap((response: any) => {})
    );
  };
  updatePreferencesLocally = (type: string, localPreference: string) => {
    const findIndex = this.userPreferences.findIndex(
      (element: PreferenceJson) => element.viewName.toLowerCase() === type
    );
    if (findIndex > -1) {
      this.userPreferences[findIndex].preferenceJson = JSON.stringify({
        preferenceJson: localPreference,
      });
    } else {
      const preferenceJSONObject: PreferenceJson = {
        viewName: type.toUpperCase(),
        preferenceJson: JSON.stringify({ preferenceJson: localPreference }),
      };
      this.userPreferences.push(preferenceJSONObject);
    }
  };

  cancelMeetingInvite = (
    id: string
  ): Observable<cancelMeetingInviteResponse> => {
    const url = `${environment.salesRegimeServices}/v1/user/msal/cancelNextBookingEvent`;
    return this.http.post(url, { meetingId: id }).pipe(
      catchError(this.handleError),
      tap((response: cancelMeetingInviteResponse) => {})
    );
  };

  getSelectedLanguage = (): string => this.userInfo.language;

  setUserLanguage = (language: string): Observable<LanguageResponse> => {
    const url = `${environment.referralServices}/v1/user/current/language/`;
    const data = { language };
    return this.http.post(url, data).pipe(
      catchError(this.handleError),
      tap((response: LanguageResponse) => {})
    );
  };

  getMenu(): MenuItem[] {
    return this.userInfo ? this.userInfo.menus : [];
  }

  hasMenuAccess = (menuName: string): boolean => {
    const menuList = this.userInfo ? this.userInfo.menus : [];
    let hasAccess = false;
    menuList.forEach((menuItem) => {
      if (menuItem.name == menuName) {
        hasAccess = menuItem.access;
      }
    });
    return hasAccess;
  };

  getMicrosoftURL = (): Observable<MicrosoftURLResponse> => {
    const url = `${environment.salesRegimeServices}/v1/user/msal/redirectUrl`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: MicrosoftURLResponse) => {})
    );
  };

  setDeclined = (): Observable<DeclinedURLResponse> => {
    const url = `${environment.salesRegimeServices}/v1/user/msal/declined`;
    return this.http.post(url, {}).pipe(
      catchError(this.handleError),
      tap((response: DeclinedURLResponse) => {})
    );
  };

  setMsalAccessTokenPresent = () => {
    this.userInfo.msalAccessTokenPresent = false;
  };

  setMenu = (menus: MenuItem[]) => (this.userInfo.menus = menus);

  getBanks = () => this.userInfo.banks;
  setUserInfo(data: UserInfo): void {
    this.userInfo = { ...data };
  }
  getUserDevice = () => this.showOnDesktop;

  setUserDevice = (deviceType: boolean): void => {
    this.showOnDesktop = !deviceType;
  };

  setSelectedBankCountry = (bankCountry: string): void => {
    this.selectedBankCountry = bankCountry;
  };

  getSelectedBankCountry = (): string => this.selectedBankCountry;

  setAvailableModuleFeatures = (data: FeatureModuleVersion): void => {
    this.setFeaturesDataLoaded();
    this.availableModuleFeatures = { ...data };
  };

  getAvailableModuleFeatures = (): FeatureModuleVersion =>
    this.availableModuleFeatures;

  setUserAuthInfo(data: UserData): void {
    this.userAuthInfo = { ...data };
  }

  setFeaturesDataLoaded = () => {
    this.isFeaturesDataLoaded = true;
  };

  getFeaturesDataLoaded = (): boolean => this.isFeaturesDataLoaded;

  getAllQuickTexts = (): Observable<QuickTextDetailsResponse> => {
    const url = `${environment.salesRegimeServices}/v1/quickTexts`;
    return this.http.get(url).pipe(
      tap((response: QuickTextResponse) => {}),
      catchError(this.handleError)
    );
  };

  checkUserActive = (): Observable<CheckUserActive> => {
    return this.http
      .get(`${environment.referralServices}/v1/usersession/remainingtime/`)
      .pipe(
        catchError(this.handleError),
        tap((response: CheckUserActive) => {})
      );
  };

  getModuleFeatures = (): Observable<FeatureModuleVersion> => {
    return this.http
      .get(`${environment.salesRegimeServices}/v1/app/features `)
      .pipe(
        catchError(this.handleError),
        tap((response: FeatureModuleVersion) => {})
      );
  };
  logout = (): Observable<LogoutResponse> => {
    return this.http.post('/v1/logout', {}).pipe(
      catchError(this.handleError),
      tap((response: LogoutResponse) => {})
    );
  };

  handleError(error: HttpErrorResponse): Observable<any> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
