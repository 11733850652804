import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdhocMessageService {
  conversationListUpdated = new Subject();
  constructor() {}

  updateConversationListUpdated = (data: any) => {
    this.conversationListUpdated.next(data);
  };

  onUpdateConversationListUpdated = () =>
    this.conversationListUpdated.asObservable();
}
