import { NgModule } from '@angular/core';
import {
  HttpClient,
  HttpClientXsrfModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CloverHttpInterceptor } from '@shared/services/http.interceptors';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/application/app.component';
import { FeaturesComponent } from '@app/features/features.component';
import { LeftMenuComponent } from '@shared/component/left-menu/left-menu.component';
import { HeaderComponent } from '@shared/component/header/header.component';
import { SearchResultComponent } from './features/search-results/search-result/search-result.component';
import { NotificationsComponent } from './features/notifications/notifications.component';
import { ToasterComponent } from './shared/component/toaster/toaster.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorI18n } from './shared/utils/mat-paginator';
import { CallDialogComponent } from './shared/call/call-dialog/call-dialog.component';
import { SharedModule } from './shared/shared.module';
import { NotificationDrawerComponent } from './shared/component/notification-drawer/notification-drawer.component';
import { AdhocMessageContainerComponent } from './shared/component/adhoc-message-container/adhoc-message-container.component';
import { SendInviteComponent } from './shared/send-invite/send-invite.component';
import { RefineSearchComponent } from './features/search-results/refine-search/refine-search.component';
import { AllNotificationsDialogComponent } from './features/all-notifications-dialog/all-notifications-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    FeaturesComponent,
    HeaderComponent,
    LeftMenuComponent,
    SearchResultComponent,
    NotificationsComponent,
    ToasterComponent,
    CallDialogComponent,
    NotificationDrawerComponent,
    AdhocMessageContainerComponent,
    SendInviteComponent,
    RefineSearchComponent,
    AllNotificationsDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'Csrf-Token',
      headerName: 'Csrf-Token',
    }),
  ],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CloverHttpInterceptor,
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) =>
        new PaginatorI18n(translateService).getPaginatorIntl(),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
