export const agendaTabset = [
  {
    filter: 'email,text,visit,call,sequence_auto_assign',
    name: 'sequences',
    multiLangKey: 'agenda.task.tab1',
    count: 0,
  },
  {
    filter: 'close_date,credit_reject,workflow,pricing_override',
    name: 'opportunity-management',
    multiLangKey: 'agenda.task.tab2',
    count: 0,
  },
  {
    filter: 'reminder',
    name: 'reminder',
    multiLangKey: 'agenda.task.tab3',
    count: 0,
  },
];
export const allReminders = [
  {
    dataFieldName: 'type',
    displayName: 'Type',
  },
  {
    dataFieldName: 'when',
    displayName: 'When',
  },
  {
    dataFieldName: 'businessName',
    displayName: 'Business Name',
  },
  {
    dataFieldName: 'contactName',
    displayName: 'Contact Name',
  },
  {
    dataFieldName: 'entityType',
    displayName: 'sales Stage',
  },
  {
    dataFieldName: 'notes',
    displayName: 'Notes',
  },
  {
    dataFieldName: 'action',
    displayName: 'Action',
  },
];
const commonStaticContent = {
  somethingApiError: 'something.wrong',
  cancel: 'common.button.text.cancel',
};

export const agendaTaskDetailsStaticContent = {
  ...commonStaticContent,
  callInProgress: 'call.inprogress',
  opportunitySuccess: 'opportunities.success.message',
  leadAssignMessage: 'lead.detail.lead.assign.confirm.message',
  confirmLeadReAssign: 'lead.detail.lead.assign.confirm.title',
  reassign: 'button.reassign',
};

export const successStaticContent = {
  successCancelMessageHeader: 'agenda.appointmentdetails.meetingCancelled',
  successCancelMessage: 'agenda.appointmentdetails.meetingCancelledMessage',
};

export const pipelineManagementStaticContent = {
  ...commonStaticContent,
  opportunitySuccess: 'opportunities.success.message',
  pipelineNoTask: 'agenda.pipelineNoTaksAvailable',
  suceess: 'agenda.prospecting.detail.success',
  reminderDeleted: 'agenda.remider.deleted',
  reminderMarkasCompleted: 'agenda.reminder.markas.completed',
};
