import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  language = this.localStorageService.getItem('getSelectedLanguage');
  languageChanged = new BehaviorSubject(this.language);
  constructor(
    private localStorageService: LocalStorageService,
    private translate: TranslateService
  ) {}

  setLanguage(language: string): void {
    this.language = language;
    this.languageChanged.next(language);
  }

  getLanguage(): string {
    return this.language;
  }

  getLocaleString = (stringObject: {
    [key: string]: string;
  }): { [key: string]: string } => {
    const localeString: { [key: string]: string } = {};
    const keyArray = Object.keys(stringObject);
    for (let index = 0; index < keyArray.length; index++) {
      this.translate
        .get(stringObject[keyArray[index]])
        .subscribe((text: string) => {
          localeString[keyArray[index]] = text;
        });
    }
    return localeString;
  };
}
