import { Injectable } from '@angular/core';
import { DataSharingService } from './data-sharing.service';
import { WebsocketsService } from '@app/websockets.service';
import { productionUrl } from '@app/appConstants';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HeaderService } from './header.service';
import { MessageService } from '@app/features/message/message.service';
import { ConfirmComponent } from '../confirm/confirm.component';
import { LanguageService } from './language.service';
import { TranslateService } from '@ngx-translate/core';
import { replaceStringVal } from '../utils/functions';
import { Router } from '@angular/router';
import { AdhocMessageService } from './adhoc-message.service';
import { realTimeStaticContent } from '../sharedConstants';

@Injectable({
  providedIn: 'root',
})
export class RealTimeEventService {
  expirePopUpRef!: MatDialogRef<any>;
  userData = { redirectUrl: '', userid: '' };
  window: any = window;
  staticContent: { [key: string]: string } = this.language.getLocaleString(
    realTimeStaticContent
  );
  domainType = productionUrl.includes(window.location.origin) ? 'prod' : 'qa';
  constructor(
    private dataSharing: DataSharingService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private router: Router,
    private language: LanguageService,
    private translate: TranslateService,
    private headerService: HeaderService,
    private webSocket: WebsocketsService,
    private adhocMessageService: AdhocMessageService
  ) {
    this.webSocket.attemptToConnectEvent.subscribe({
      next: () => {
        this.webSocketMessage(this.userData.userid);
      },
    });
    this.language.languageChanged.subscribe({
      next: (res: string) => {
        if (res) {
          this.translate.setDefaultLang(res);
        }
      },
    });
    this.checkTranslationLoaded();
  }

  checkTranslationLoaded = () => {
    this.translate.get('shared.common.text.confirm').subscribe(
      (text: string) => {
        if (text === 'shared.common.text.confirm') {
          setTimeout(() => {
            this.checkTranslationLoaded();
          }, 1000);
        } else {
          this.staticContent = this.language.getLocaleString(
            realTimeStaticContent
          );
        }
      },
      () => {
        setTimeout(() => {
          this.checkTranslationLoaded();
        }, 1000);
      }
    );
  };

  webSocketMessage = (userId: string) => {
    if (!userId) return;
    this.webSocket.getSocketOn(userId.toLowerCase(), this.domainType);
    this.webSocket.socket.onmessage = (event) => {
      const websocketData = JSON.parse(event.data);
      if (websocketData.eventName === 'genesysLeadAssignment') {
        this.openGenesysLeadAssignPopup(websocketData);
      } else if (websocketData.eventName === 'leadReassignmentEvent') {
        this.openLeadAssignPopup(websocketData);
        this.dataSharing.updateSequenceTaskNotification(websocketData);
      } else if (websocketData.eventName === 'concurrentSessionEvent') {
        this.concurrentNewSessionPopup(websocketData);
      } else if (websocketData.eventName === 'NewMessageRTNotification') {
        this.newMessageReceived(websocketData.eventData);
      } else if (
        ['bellNotificationEvent', 'prospectListNotificationEvent'].includes(
          websocketData.eventName
        )
      ) {
        this.dataSharing.bellNotificationEvent.next(null);
        if (websocketData.eventName === 'prospectListNotificationEvent') {
          const newMoaList = {
            type: 'newMoaList',
            data: JSON.parse(websocketData.eventData),
          };
          this.headerService.newMoaNotificationReceived(newMoaList);
        }
      } else if (
        websocketData.eventName === 'LeadEventUpdate' ||
        websocketData.eventName === 'LeadConvertEvent'
      ) {
        this.dataSharing.updateLeadDataUpdated(websocketData);
        this.dataSharing.updateSequenceTaskNotification(websocketData);
      } else if (websocketData.eventName === 'OpportunityEventUpdate') {
        this.dataSharing.updateOpportunityDataUpdated(websocketData);
        this.dataSharing.updateSequenceTaskNotification(websocketData);
      } else if (websocketData.eventName === 'CallCompletedRTNotification') {
        this.dataSharing.callCompletedRTNotificationEvent.next(websocketData);
      } else if (
        ['NewAgendaRTNotification', 'sequencePausedNotification'].includes(
          websocketData.eventName
        )
      ) {
        this.dataSharing.updateSequenceTaskNotification(websocketData);
      }
    };
  };

  newMessageReceived = (data: string) => {
    const eventData = JSON.parse(data);
    this.messageService.updateUnReadMsgCountEvent.next('');
    this.headerService.newMessageReceived(eventData);
    this.headerService.playSound();
    if (this.router.url == '/messages') {
      return;
    }
    this.adhocMessageService.updateConversationListUpdated({
      action: 'new',
      data: {
        moduleName: eventData.entityType,
        userType: eventData.userType,
        user: {
          entityId: eventData.entityId,
          firstName: eventData.firstName,
          lastName: eventData.lastName,
        },
      },
    });
  };
  concurrentNewSessionPopup = (data: any) => {
    const concurrentData = {
      type: 'conCurrentNewSession',
      data: JSON.parse(data.eventData),
    };
    this.headerService.newNotificationReceived(concurrentData);
  };

  openGenesysLeadAssignPopup = (data: any) => {
    const leadData = JSON.parse(data.eventData);
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '600px',
      data: {
        title: this.staticContent.leadGenesysTitle,
        message: replaceStringVal(
          this.staticContent.leadGenesysBody,
          leadData.genesysPhoneNumber,
          '<phoneNumber>'
        ),
        cancelButton: this.staticContent.cancel,
        confirmButton: this.staticContent.confirm,
      },
    });
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'success') {
        this.routeToNewPage(leadData.leadId);
      }
    });
  };
  openLeadAssignPopup = (data: any) => {
    const leadData = JSON.parse(data.eventData);
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '600px',
      data: {
        title: this.staticContent.leadReassignmentTitle,
        message: replaceStringVal(
          this.staticContent.leadReassignmentBody,
          leadData.leadTitle,
          '<newLeadName>'
        ),
        cancelButton: this.staticContent.cancel,
        confirmButton: this.staticContent.confirm,
      },
    });
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'success') {
        this.routeToNewPage(leadData.leadId);
      }
    });
  };
  routeToNewPage = (leadId: string) => {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/leads/details/${leadId}`])
    );
    window.open(url, '_blank');
  };
}
