<ng-container *ngIf="checkDesktopDevice">
  <div *ngIf="isUserInfoLoaded">
    <clover-header></clover-header>
    <router-outlet></router-outlet>
  </div>
  <clover-adhoc-message-container  *ngIf="isUserInfoLoaded"></clover-adhoc-message-container>
  <mat-spinner
    class="align-center"
    [hidden]="isUserInfoLoaded"
    diameter="50"
  ></mat-spinner>
</ng-container>

<ng-container *ngIf="!checkDesktopDevice">
  <div class="container h-100 d-flex justify-content-center align-items-center">
    <div class="sorry-face"><img src="assets/images/icons/icon_angle_down.svg"><br />Not accessible to Mobile</div>
  </div>
</ng-container>
