import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserInfoService } from '../services/user-info.service';
import { SharedService } from '../shared.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  settingsChildUrls = ['branches', 'users', 'hierarchy'];
  constructor(
    public router: Router,
    private userInfo: UserInfoService,
    private sharedService: SharedService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const showOnDesktop = this.userInfo.getUserDevice();
    const menu = this.userInfo.getMenu();
    if (!menu.length) {
      return false;
    }
    const menuObj: any = {};
    const path = route.url[0].path;
    let hasAccess = false;
    menu.forEach((m) => {
      menuObj[m.name] = m.access;
    });
    if (path === 'my-referral' || path === 'referral-activity') {
      if (path === 'my-referral') {
        hasAccess = menuObj.myreferrals;
      } else {
        hasAccess = menuObj.referralactivity && showOnDesktop;
      }
      if (!hasAccess) {
        if (menuObj.referralactivity && showOnDesktop) {
          this.router.navigate(['/referral/referral-activity']);
        } else {
          this.router.navigate(['/referral/my-referral']);
        }
      }
    }
    if (this.settingsChildUrls.includes(path)) {
      if (showOnDesktop) {
        hasAccess = true;
      } else {
        this.router.navigate(['/settings']);
      }
    }
    if (path === 'notifications') {
      hasAccess = true;
    }
    if (path === 'quickTexts') {
      const userDetails = this.sharedService.getProfileDetails();
      if (
        userDetails &&
        (userDetails.role === '1' || userDetails.role === '6' || userDetails.role === '7')
      ) {
        hasAccess = true;
      } else {
        this.router.navigate(['/settings']);
      }
    }
    return hasAccess;
  }
  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    const menu = this.userInfo.getMenu();
    const isReferralUser = this.sharedService.isReferralUser();
    const showOnDesktop = this.userInfo.getUserDevice();
    if (!menu.length) {
      return false;
    }
    const menuObj: any = {};
    menu.forEach((m) => {
      menuObj[m.name] = m.access;
    });
    let hasAccess = false;
    switch (route.path) {
      case 'overview':
        hasAccess = menuObj.overview && showOnDesktop;
        break;
      case 'agenda':
        hasAccess = menuObj.agenda && showOnDesktop;
        break;
      case 'activityfeed':
        hasAccess = menuObj.activityfeed && showOnDesktop;
        break;
      case 'dashboard':
        hasAccess = menuObj.dashboard && showOnDesktop;
        break;
      case 'moa':
        hasAccess = menuObj.prospect && showOnDesktop;
        break;
      case 'opportunities':
        hasAccess = (menuObj.opportunities || isReferralUser) && showOnDesktop;
        break;
      case 'audience':
        hasAccess = menuObj.audience && showOnDesktop;
        break;
      case 'referral':
        hasAccess =
          menuObj.myreferrals || (menuObj.referralactivity && showOnDesktop);
        break;
      case 'myteam':
        hasAccess = menuObj.myteam && showOnDesktop;
        break;
      case 'myaccounts':
        hasAccess = menuObj.myaccounts && showOnDesktop;
        break;
      case 'branch':
        hasAccess = menuObj.branch && showOnDesktop;
        break;
      case 'sequence':
        hasAccess = menuObj.sequencebuilder && showOnDesktop;
        break;
      case 'leads':
        hasAccess = menuObj.leads || isReferralUser;
        break;
      case 'sales':
        hasAccess = menuObj.salesrouting && showOnDesktop;
        break;
      case 'settings':
        hasAccess = menuObj.settings;
        break;
      case 'campaign':
        hasAccess = menuObj['campaign planner'] && showOnDesktop;
        break;
      case 'messages':
        hasAccess = menuObj.messages && showOnDesktop;
        break;
      case 'academy':
        hasAccess = menuObj.cloveracademy && showOnDesktop;
        break;
    }
    if (!hasAccess) {
      switch (true) {
        case menuObj.overview && showOnDesktop:
          this.router.navigate(['/overview']);
          break;
        case menuObj.leads:
          this.router.navigate(['/leads']);
          break;
        case menuObj.agenda && showOnDesktop:
          this.router.navigate(['/agenda']);
          break;
        case menuObj.activityfeed && showOnDesktop:
          this.router.navigate(['/activityfeed']);
          break;
        case menuObj.prospect && showOnDesktop:
          this.router.navigate(['/moa/prospect']);
          break;
        case menuObj.dashboard && showOnDesktop:
          this.router.navigate(['/dashboard']);
          break;
        case menuObj.audience && showOnDesktop:
          this.router.navigate(['/audience']);
          break;
        case menuObj.audience && showOnDesktop:
          this.router.navigate(['/opportunities']);
          break;
        case menuObj.myreferrals:
          this.router.navigate(['/referral/my-referral']);
          break;
        case menuObj.referralactivity && showOnDesktop:
          this.router.navigate(['/referral/referral-activity']);
          break;
        case menuObj.myteam && showOnDesktop:
          this.router.navigate(['/myteam']);
          break;
        case menuObj.myaccounts && showOnDesktop:
          this.router.navigate(['/myaccounts']);
          break;
        case menuObj.branch && showOnDesktop:
          this.router.navigate(['/branch']);
          break;
        case menuObj.sequencebuilder && showOnDesktop:
          this.router.navigate(['/sequence']);
          break;
        case menuObj.salesrouting && showOnDesktop:
          this.router.navigate(['/sales']);
          break;
        case menuObj.settings:
          this.router.navigate(['/settings']);
          break;
        case menuObj['campaign planner'] && showOnDesktop:
          this.router.navigate(['/campaign']);
          break;
        case menuObj.academy && showOnDesktop:
          this.router.navigate(['/academy']);
          break;
        case menuObj.messages && showOnDesktop:
          this.router.navigate(['/messages']);
          break;
      }
    }
    return hasAccess;
  }
}
