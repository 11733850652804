import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {
  PersonalResponse,
  TagNames,
  UpdateRegisterMFARequest,
  UpdateRegisterMFAResponse,
  UserDetails,
  UserDetailsResponse,
  UserMFADataResponse,
  UserMFADeviceDataResponse,
} from '@app/model/interfaces/settings';
import { environment } from '../../environments/environment';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  tagNames!: TagNames[];
  profileDetails!: UserDetails;
  constructor(private http: HttpClient) {}

  getTagNames = () => {
    return this.tagNames;
  };

  setTagNames = (data: TagNames[]): void => {
    this.tagNames = data;
  };

  getProfileDetails = () => this.profileDetails;
  setProfileDetails = (data: UserDetails) => {
    this.profileDetails = { ...data };
  };

  getUserDetails(): Observable<UserDetailsResponse> {
    return this.http
      .get(`${environment.referralServices}/v1/users/current/`)
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  isReferralUser = () => this.profileDetails && ['3', '4', '5'].includes(this.profileDetails.role);

  isBcUser = () => {
    return this.profileDetails && this.profileDetails.role === '6'
  }

  isSdUser = () => {
    return this.profileDetails && this.profileDetails.role === '7'
  }

  getPersonalText(): Observable<PersonalResponse> {
    return this.http
      .get<PersonalResponse>(
        `${environment.salesRegimeServices}/v1/personalizedTexts`
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  replaceQuickText(entityType: string, entityId: number, quickText: string): Observable<any> {
    return this.http
      .post<any>(
        `${environment.salesRegimeServices}/v1/${entityType}/${entityId}/replaceQuickTexts`, {
        message: quickText
      }
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  getCurrentUserMFA(): Observable<UserMFADataResponse> {
    return this.http
      .get(`${environment.salesRegimeServices}/v1/users/current/mfa`)
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  updateRegisterMFA(payload: UpdateRegisterMFARequest): Observable<UpdateRegisterMFAResponse> {
    return this.http
      .post<any>(
        `${environment.salesRegimeServices}/v1/users/current/mfa`, payload
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  getDevicesMFA(): Observable<UserMFADeviceDataResponse> {
    return this.http
      .get(`${environment.salesRegimeServices}/v1/users/current/mfa/devices`)
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  handleError(error: HttpErrorResponse): Observable<any> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
