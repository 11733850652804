import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsPopup } from '@app/model/interfaces/notification';
import { DataSharingService } from '@app/shared/services/data-sharing.service';
import { HeaderService } from '@app/shared/services/header.service';
import { LanguageService } from '@app/shared/services/language.service';
import { notificationDrawerStaticContent } from '@app/shared/sharedConstants';
import { replaceStringVal } from '@app/shared/utils/functions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'clover-notification-drawer',
  templateUrl: './notification-drawer.component.html',
  styleUrls: ['./notification-drawer.component.scss'],
})
export class NotificationDrawerComponent implements OnInit {
  currentNotification: NotificationsPopup[] = [];
  staticContent = this.languageService.getLocaleString(
    notificationDrawerStaticContent
  );
  constructor(
    private headerService: HeaderService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private router: Router,
    private dataSharingService: DataSharingService
  ) {}

  ngOnInit(): void {
    this.languageService.languageChanged.subscribe({
      next: (res: string) => {
        this.translate.setDefaultLang(res);
      },
    });

    this.headerService.onNotificationReceived().subscribe((res: any) => {
      res.message = this.getConCurrentSessionMessage(res);
      this.currentNotification.unshift(res);
    });
    this.headerService.onMoaNotificationReceived().subscribe((res: any) => {
      this.currentNotification.unshift(res);
    });
  }
  removeNotification = (index: number) => {
    this.currentNotification.splice(index, 1);
  };

  gotoList = (listId: number, index: number) => {
    this.router.navigate([`/moa/yourlist/details/${listId}`]);
    this.removeNotification(index);
  };
  getConCurrentSessionMessage = (notification: NotificationsPopup): string => {
    if (notification.type === 'conCurrentNewSession') {
      return replaceStringVal(
        this.staticContent.concurrentNewSessionMessage,
        notification.data.clientIP,
        '<IPAddress>'
      );
    } else {
      //notification.type === 'conCurrentExistingSession'
      return replaceStringVal(
        this.staticContent.conCurrentExistingSessionMessage,
        String(notification.data.length - 1),
        '<count>'
      );
    }
  };
}
