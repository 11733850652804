import { Component, Inject, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
  selector: 'clover-confirm',
  template: `
    <div
      class="px-4 increaseModalHeight"
      [ngClass]="{
        'd-flex flex-column justify-content-center align-items-center':
          data.align === 'center'
      }"
    >
      <img
        *ngIf="data.align === 'center'"
        [src]="'assets/images/icons/' + data.imgIcon"
        alt="warning"
        [ngClass]="{ 'pb-3': data.align === 'center' }"
      />
      <h3
        class="section-title2 d-flex"
        [ngClass]="{
          'fs-26 fw-bold pb-2': data.align === 'center'
        }"
      >
        {{ data.title }}
      </h3>
      <p
        class="mt-2"
        [ngClass]="{
          'pageTitle-desc': !data.mobileConsent,
          'mobileConsent-desc': data.mobileConsent,
          'fs-16': data.align === 'center'
        }"
      >
        <span [innerHTML]="data.message | safe : 'html'"></span>
        <span
          class="link"
          *ngIf="data.link"
          (click)="navigateTo(data.link.url)"
          >{{ data.link.displayText }}</span
        >
      </p>
      <div class="d-flex justify-content-end mt-2">
        <button
          *ngIf="data.cancelButton"
          type="button"
          class="btn-link-primary"
          (click)="confirmpopup()"
        >
          <u>{{ data.cancelButton }}</u>
        </button>
        <button
          type="button"
          cdkFocusInitial
          *ngIf="data.confirmButton"
          (click)="confirmpopup(true)"
        >
          {{ data.confirmButton }}
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .increaseModalHeight {
        margin: 25px 0;
      }
      h3 {
        text-transform: none;
        font-weight: 400;
      }
      .mobileConsent-desc {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    `,
  ],
})
export class ConfirmComponent implements OnInit {
  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmComponent>
  ) {}

  ngOnInit(): void {}

  navigateTo = (url: string) => {
    this.dialogRef.close();
    this.router.navigateByUrl(url);
  };
  confirmpopup = (confirm = false) => {
    if (confirm) {
      if(this.data.type) {
        this.dialogRef.close({
          action: 'success',
          type: this.data.type
        }); 
      }
      else {
        this.dialogRef.close('success');
      }
    } else {
      this.dialogRef.close();
    }
  };
}
